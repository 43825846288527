<template>
    <div class="iseq-snackbar-queue" style="position: fixed; right: 20px; bottom: 20px;">
        <v-card width="20vw" dark :color="item.type" v-for="item in visibleItems" :key="item.id">
            <div class="iseq-snackbar-queue__snackbar">
                <v-progress-circular v-if="item.type === 'request'"
                                     class="iseq-snackbar-queue__snackbar__progress"
                                     width="2"
                                     size="20"
                                     indeterminate></v-progress-circular>

                <v-icon v-if="item.icon"
                        class="iseq-snackbar-queue__snackbar__icon">
                    {{item.icon}}
                </v-icon>

                <span class="iseq-snackbar-queue__snackbar__text">
                    {{ item.message }}
                </span>

                <v-btn class="iseq-snackbar-queue__snackbar__close-button"
                       icon
                       v-if="item.id !== missingConnectionSnackbarId"
                       @click="removeItem(item.id)">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'IseqSnackbarQueue',
        computed: {
            ...mapState('snackbarQueue', ['items', 'missingConnectionSnackbarId']),
            visibleItems () {
                let items = this.items.filter(item => !item.message.includes('Uploading') && item.message!=='');
                const itemUploading = this.items.find(item => item.message.includes('Uploading'));
                if (itemUploading) {
                    items.push(itemUploading);
                }
                return items;
            }
        },
        methods: {
            removeItem(id) {
                this.$store.commit('snackbarQueue/removeItem', {id: id, force: true});
            }
        },
    }
</script>

<style lang="scss"
       scoped>
    .iseq-snackbar-queue {
        &__snackbar {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px;
            margin-top: 5px;

            &__progress{
                margin: 4px;
            }

            &__icon {
                margin: 4px;
            }

            &__close-button {
                margin-right: 4px;
                margin-left: auto;
            }
        }
    }
</style>
import request from '@/helpers/request.js'

export default {
  namespaced: true,
  state: {
    workflows: [],
    workflow: {},
    notParsedWorkflows: [],
    wdlsWithoutPrice: []
  },
  mutations: {
    setWorkflows(state, payload) {
      // Setting plain workflows list
      state.workflows = payload;

      // Dividing list by tags

      const tags = [
        "Clinical WES/WGS",
        "Consumer WES/WGS",
        "Research",
        "Utilities",
      ];

      payload.map(workflow => workflow.tag = workflow.tag ? workflow.tag : "Undefined");
      payload.sort(function (a, b) {
        if (tags.indexOf(a.tag)+1 && tags.indexOf(b.tag)+1){
          if (tags.indexOf(a.tag) === tags.indexOf(b.tag)) return 0;
          else if (tags.indexOf(a.tag) < tags.indexOf(b.tag)) return -1;
          else return 1
        }
        else if (tags.indexOf(a.tag)+1){
          return 1
        }
        else if (tags.indexOf(b.tag)+1){
          return -1
        }
        else {
          if (a.tag === b.tag) return 0;
          else if (a.tag < b.tag) return 1;
          else return -1
        }
      });

      let groupedData = [];

      groupedData.push({divider: true});
      groupedData.push({header: payload[0].tag});
      groupedData.push({divider: true});
      groupedData.push(payload[0]);

      for (let i = 1; i < payload.length; i++) {
        if (payload[i].tag !== groupedData[groupedData.length - 1].tag) {
          groupedData.push({divider: true});
          groupedData.push({header: payload[i].tag});
          groupedData.push({divider: true});
          groupedData.push(payload[i])
        } else {
          groupedData.push(payload[i])
        }
      }

      // Setting tagged workflows list
      state.workflowsGrouped = groupedData;
    },
    setWorkflow(state, payload) {
      state.workflow = payload;
    },
    setNotParsedWorkflows(state, payload) {
      state.notParsedWorkflows = payload;
    },
    setWdlsWithoutPrice(state, payload) {
      state.wdlsWithoutPrice = payload;
    },
    cleanWorkflow(state) {
      state.workflow = {};
    },
    cleanAllData(state) {
      state.workflows = [];
    }
  },
  actions: {
    getWorkflows({commit}, payload) {
      const endpoint = payload.organization ? `admin/organizations/${payload.organization}/wdl` : 'admin/wdl';
      request.request({
        endpoint: endpoint,
        callback: (data) => {
          commit('setWorkflows', data);
        }
      })
    },
    getWorkflow({commit}, payload) {
      request.request({
        endpoint: `wdl/${payload.workflowId}`,
        callback: (data) => {
          commit('setWorkflow', data);
        }
      })
    },
    setWorkflowNewPrice({dispatch}, payload) {
      request.request({
        endpoint: 'admin/wdl/price',
        method: 'post',
        data: {
          workflowId: payload.workflowId,
          price: payload.price
        },
        callback: () => {
          if (payload.organization) {
            dispatch('getWorkflows', {organization: payload.organization});
          } else if (payload.workflowId) {
            dispatch('getWorkflow', {workflowId: payload.workflowId});
          }
        }
      });
    },
    getWdlsWithoutPrice({commit}) {
      request.request({
        endpoint: "wdl/without-price",
        callback: function (data) {
          commit("setWdlsWithoutPrice", data.wdlsWithoutPrice);
        }
      })
    },
    getNotParsedProdWorkflows({commit}) {
      request.request({
        endpoint: "wdl/not-parsed/prod",
        service: "API_WDL",
        callback: function (data) {
          commit("setNotParsedWorkflows", data);
        }
      })
    },

    // eslint-disable-next-line no-empty-pattern
    getWorkflowFromURL({}, payload) {
      return new Promise((resolve, reject) => {
        request.request({
          endpoint: `wdl/parse?${payload.variant ? "variant=" + payload.variant + '&' : ''}hidden=true&validate=true&url=${payload.url}`,
          service: "API_WDL",
          callback: function (data) {
            resolve(data);
          },
          errorCallback: function (error) {
            reject(error);
          }
        })
      })
    },
  }
}
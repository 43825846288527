<template>
  <v-dialog v-model="active"
            persistent
            max-width="600px">
    <div class="s3-credentials-modal">
      <div class="s3-credentials-modal__header">
        <span class="s3-credentials-modal__header__title">
            Set S3 credentials for current organization:
        </span>
      </div>

      <v-form v-model="isFormValid"
              ref="newPriceForm"
              class="s3-credentials-modal__form">

        <v-text-field v-model="accessKey"
                      label="Access key"
                      outlined
                      :rules="fieldRules"></v-text-field>

        <v-text-field v-model="secretKey"
                      label="Secret key"
                      outlined
                      :rules="fieldRules"></v-text-field>

        <v-text-field v-model="region"
                      label="Region"
                      outlined
                      :rules="fieldRules"></v-text-field>
      </v-form>


      <div class="s3-credentials-modal__buttons">
        <v-iseq-btn @click="closeModal"
                    type="secondary">
          Close
        </v-iseq-btn>

        <v-iseq-btn :disabled="!isFormValid"
                    type="primary"
                    @click="sendS3CredentialsData">
          Confirm
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IseqButton from "../ui/IseqButton";

export default {
  name: "setS3CredentialsModal",
  components: {
    "v-iseq-btn": IseqButton
  },
  props: {
    organizationUuid: {
      type: String
    }
  },
  data: () => {
    return {
      active: true,
      region: "",
      accessKey: "",
      secretKey: "",
      isFormValid: false,
      fieldRules: [
        v => !!v && v.length>0 || "Field cannot be empty",
      ]
    }
  },
  methods: {
    sendS3CredentialsData() {
      this.$store.dispatch('organization/setS3Credentials', {
        data: {
          region: this.region,
          accessKey: this.accessKey,
          secretKey: this.secretKey
        },
        uuid: this.organizationUuid
      });
      this.$emit('closed');
    },
    closeModal() {
      this.$emit('closed');
    }
  },
}
</script>

<style lang="scss"
       scoped>
.s3-credentials-modal {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  color: var(--font-color);

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 1.6em;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .iseq-btn {
      margin-right: 10px;
    }
  }

  &__warning {
    margin-top: 10px;
    font-size: 0.75em;
  }
}
</style>
<template>
  <div class="iseq-login">
    <v-iseq-card class="iseq-login__content">

      <img alt="IntelliseqFlow Logo"
           class="iseq-login__content__logo"
           :src="logoPath"/>

      <div class="iseq-login__content__input-fields"
           v-if="form==='login'">

        <span class="iseq-login__content__input-fields__title">Sign in to your account</span>

        <span class="iseq-login__content__input-fields__name">Email</span>

        <v-iseq-textfield v-model="username"
                          type="email"
                          hide-details
                          @keyup.enter="signIn"
                          class="iseq-login__content__input-fields__field"
        />

        <template v-if="flags.isUsernameEmpty">
          <span class="iseq-login__content__input-fields__error">Username cannot be empty</span>
        </template>

        <span class="iseq-login__content__input-fields__name">Password</span>

        <v-iseq-textfield v-model="password"
                          type="password"
                          hide-details
                          @keyup.enter="signIn"
                          class="iseq-text-field iseq-login__content__input-fields__field"
        />

        <template v-if="flags.isNotAuthorized">
          <span class="iseq-login__content__input-fields__error">Email and password don't match</span>
        </template>

        <div class="iseq-login__content__button-field">
          <v-iseq-btn @click="signIn"
                      v-if="!signingInInProgress"
                      class="iseq-login__content__button-field__button">
            Sign in
          </v-iseq-btn>

          <v-iseq-btn v-else
                      disabled
                      class="iseq-login__content__button-field__button">
            Signing in...
          </v-iseq-btn>
        </div>
      </div>

      <div class="iseq-login__content__input-fields"
           v-if="form==='2FALoginConfirmation'">

        <span class="iseq-login__content__input-fields__title">Confirm sign in</span>

        <span class="iseq-login__content__input-fields__name">SMS code</span>

        <v-iseq-textfield v-model="code"
                          filled
                          @keyup.enter="confirm2FACode()"
                          class="iseq-login__content__input-fields__field"
        />

        <template v-if="flags.invalidCode">
          <span class="iseq-login__content__input-fields__error">Code invalid or expired. Try again.</span>
        </template>

        <div class="iseq-login__content__button-field">
          <v-iseq-btn @click="confirm2FACode()"
                      v-if="!signingInInProgress"
                      class="iseq-login__content__button-field__button">
            Sign in
          </v-iseq-btn>

          <v-iseq-btn v-else
                      disabled
                      class="iseq-login__content__button-field__button">
            Signing in...
          </v-iseq-btn>
        </div>
      </div>
    </v-iseq-card>
  </div>
</template>

<script>
import {AmplifyEventBus} from 'aws-amplify-vue';
import {Auth} from 'aws-amplify';
import store from '@/store';
import IseqCard from '@/components/ui/IseqCard';
import IseqButton from '@/components/ui/IseqButton';
import IseqTextField from '@/components/ui/IseqTextField.vue';

async function getJWT() {
  const session = await Auth.currentSession();
  const jwt = session.idToken.jwtToken;
  store.commit('security/updateToken', jwt);
}

export default {
  name: "Login",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton,
    "v-iseq-textfield": IseqTextField
  },
  data: function () {
    return {
      logoPath: require(process.env.VUE_APP_LOGO_DARK_PATH),
      user: undefined,
      form: "login",
      username: "",
      password: "",
      code: "",
      flags: {
        isNotAuthorized: false,
        isUsernameEmpty: false,
        invalidCode: false,
      },
      signingInInProgress: false
    }
  },
  mounted() {
    Auth.currentAuthenticatedUser().catch(err => console.log(err));

    AmplifyEventBus.$on("authState", eventInfo => {
      if (eventInfo === "signedIn") {
        getJWT();
        this.$router.push({path: "/"});
      }
    });
  },
  methods: {
    async signIn() {
      if (this.validateUsername(this.username)) {
        try {
          this.signingInInProgress = true;
          this.user = await Auth.signIn(this.username, this.password);
          this.signingInInProgress = false;
          if (this.user.challengeName === "NOMFA" || this.user.challengeName === undefined) {
            await this.$router.push("/");
          } else {
            this.form = "2FALoginConfirmation";
          }
        } catch (error) {
          this.signingInInProgress = false;
          // TODO - Change behaviour for non-confirmed user
          if (error.code === "UserNotConfirmedException") {
            await this.$router.push({
              name: "signup",
              params: {
                signUpConfirm: true,
                username: this.username,
                password: this.password
              }
            });
          } else if (error.code === "NotAuthorizedException") {
            this.flags.isNotAuthorized = true;
          } else if (error.code === "InvalidParameterException") {
            this.flags.isNotAuthorized = true;
          } else {
            console.log(error)
          }
        }
      }
    },
    async confirm2FACode() {
      try {
        this.resetFlags(['invalidCode']);
        this.signingInInProgress = true;
        await Auth.confirmSignIn(this.user, this.code, "SMS_MFA");
        this.signingInInProgress = false;
        await this.$router.push("/");
      } catch (error) {
        this.signingInInProgress = false;
        if (error.code === "CodeMismatchException") {
          this.flags.invalidCode = true;
        } else {
          console.log(error);
        }
      }
    },
    resetFlags(values) {
      for (const flag of values) {
        this.flags[flag] = false
      }
    },
    validateUsername(username) {
      this.resetFlags(['isUsernameEmpty']);
      if (username.length === 0) {
        this.flags.isUsernameEmpty = true;
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss"
       scoped>

.iseq-login {
  min-height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;

  &__content {
    width: 450px;
    margin-top: 100px;

    &__logo {
      margin-top: -130px;
      margin-bottom: 50px;
      padding: 20px;
      margin-left: calc(50% - 150px);
      width: 300px;
    }

    &__button-field {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &__button {
        width: 50% !important;
      }
    }

    &__input-fields {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      margin-top: -20px;
      padding: 0px 40px 0px;

      &__title {
        color: var(--dark);
        font-size: 1.7em;
        font-weight: 700;
        text-align: center;
        margin-bottom: 25px;
      }

      &__name {
        color: var(--dark);
        font-size: 1.3em;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__field {
        margin-bottom: 25px;
      }

      &__error {
        color: var(--danger-color);
        margin-top: -20px;
        font-size: 1.0em;
        font-weight: 700;
        text-align: left;
      }
    }
  }
}
</style>
<template>
    <v-dialog v-model="active"
              persistent
              max-width="600px">
        <div class="organization-delete-modal">
            <div class="organization-delete-modal__header">
                <span class="organization-delete-modal__header__title">
                    Delete organization: {{organization.name}}
                </span>
            </div>

            <div class="organization-delete-modal__description">
                <span class="organization-delete-modal__description__text">
                    In order to delete organization enter its name ({{organization.name}}) below and click "Delete" button.<br/><br/>
                    (Please note that all projects, samples and analyses belonging to this organization will be deleted along it).
                </span>
            </div>

            <v-form v-model="isDeleteOrganizationFormValid"
                    ref="organizationDeleteForm"
                    class="organization-delete-modal__form">

                <v-text-field v-model="userprovidedOrganizationName"
                              label="Confirm organization's name"
                              outlined
                              :rules="organizationNameRules"></v-text-field>
            </v-form>


            <div class="organization-delete-modal__buttons">
                <v-iseq-btn @click="closeModal"
                            type="secondary">
                    Close
                </v-iseq-btn>

                <v-iseq-btn :disabled="!isDeleteOrganizationFormValid"
                            type="red"
                            @click="confirmDeletion">
                    Delete
                </v-iseq-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import IseqButton from "@/components/ui/IseqButton"

export default {
    name: "OrganizationDeleteModal",
    components: {
        "v-iseq-btn": IseqButton
    },
    props: {
        organizationUuid: {
            type: String
        }
    },
    data: function() {
        return {
            active: true,
            isDeleteOrganizationFormValid: false,
            userprovidedOrganizationName: "",
            organizationNameRules: [
                v => v === this.organization.name || "Name incorrect"
            ]
        }
    },
    computed: {
        ...mapState('organization', ['organization'])
    },
    methods: {
        confirmDeletion() {
            this.$emit('confirmed');
        },
        closeModal() {
            this.$emit('closed');
        }
    },
    created() {

    }
}
</script>

<style lang="scss"
       scoped>
.organization-delete-modal {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    color: var(--font-color);

    &__header {
        margin-bottom: 20px;

        &__title {
            font-size: 1.7em;
        }
    }

    &__description {
        margin: 0px 10px 10px 10px;

        &__text {

        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin: 0px 10px;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .iseq-btn {
            margin-right: 10px;
        }
    }
}
</style>
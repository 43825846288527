<template>
    <div class="iseq-datafield-list" :style="cssProps">
        <div v-for="row in rows" :key="row" class="iseq-datafield-list__row">
            <div v-for="column in width" :key="column" class="iseq-datafield-list__row__item">
                <v-iseq-datafield v-if="(row-1)*width + (column-1) < data.length" :data="data[(row-1)*width + (column-1)]"/>
            </div>
        </div>
    </div>
</template>

<script>
import IseqDatafield from "./IseqDatafield";

export default {
    name: "IseqDatafieldList",
    components: {
        "v-iseq-datafield": IseqDatafield
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        width: {
            type: Number,
            default: 2
        }
    },
    computed: {
        rows() {
            return Math.ceil(this.data.length/this.width);
        },
        cssProps() {
            return {
                '--item-width': `${100/this.width}%`
            }
        }
    }
}
</script>

<style lang="scss"
       scoped>
    .iseq-datafield-list {
        display: flex;
        flex-direction: column;

        &__row {
            display: flex;
            flex-direction: row;
            width: 100%;

            &__item {
                width: var(--item-width);
            }
        }
    }
</style>
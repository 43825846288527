<template>
  <div class="iseq-workflow-list">
    <v-iseq-card class="iseq-workflow-list__content" title="Workflows">
      <template
        v-if="!isDefaultList"
        v-slot:title-row
      >
        <div class="iseq-workflow-list__content__title-row">
          <v-iseq-btn @click="goToDefaultWorkflows()"
                      class="iseq-workflow-list__content__title-row__button"
                      width="200px">
            Go to default workflows
          </v-iseq-btn>
        </div>
      </template>

      <div class="iseq-workflow-list__content__description">
        <template v-if="isDefaultList">
          These are default workflows common to all organizations
        </template>

        <template v-else>
          These are workflows specific to organization:
          <span
            @click="goToOrganization()"
            class="iseq-workflow-list__content__description__link"
          >
            <v-icon
              color="dark"
            >
              {{ 'mdi-account-group' }}
            </v-icon>
            {{ organization.name }}
          </span>
        </template>
      </div>
      <v-data-table :headers="tableColumns"
                    :footer-props="{
                                          'items-per-page-options': itemsPerPageOptions
                                         }"
                    :items="workflows"
                    @click:row="goToWorkflow">
        <template #item.name="{ item }">
          <span :class="{'iseq-workflow-list__content__name-field-hidden': item.hidden}">
            {{ item.name }}
          </span>
          <template v-if="item.hidden">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon size="20px"
                        class="iseq-workflow-list__content__price-field__icon"
                        color="primary"
                        v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>This workflow was marked as "hidden" and therefore isn't visible for users</span>
            </v-tooltip>
          </template>
        </template>

        <template #item.price="{ item }">
          <div class="iseq-workflow-list__content__price-field">
            {{ item.price }}

            <v-tooltip v-if="item.price === undefined"
                       left
            >
              <template v-slot:activator="{ on }">
                <v-icon
                        v-on="on"
                        color="warning"
                >
                  mdi-alert
                </v-icon>
              </template>
              <span>This workflow doesn't have its price set</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon size="20px"
                        class="iseq-workflow-list__content__price-field__icon"
                        v-on="on"
                        @click="openPriceChangeModal(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>

              <span>Change price</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-iseq-card>

    <workflow-price-change-modal v-if="typeof workflowToChange !== 'undefined'"
                                 :workflow="workflowToChange"
                                 :from-default="isDefaultList"
                                 @closed="workflowToChange = undefined"
                                 @confirmed="changeWorkflowPrice"/>
  </div>
</template>

<script>
import IseqCard from "../../components/ui/IseqCard";
import {mapState} from "vuex";
import WorkflowPriceChangeModal from "../../components/modals/WorkflowPriceChangeModal";
import IseqButton from "../../components/ui/IseqButton";

export default {
  name: "WorkflowList",
  components: {
    WorkflowPriceChangeModal,
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton
  },
  data: function() {
    return {
      itemsPerPageOptions: [10, 20, 30, -1],
      tableColumns: [
        {text: 'Workflow name', value: 'name'},
        {text: 'Type', value: 'tag'},
        {text: 'Version', value: 'version'},
        {text: 'Price', value: 'price', width: "110px"}
      ],
      workflowToChange: undefined
    }
  },
  computed: {
    ...mapState('workflow', ['workflows']),
    ...mapState('organization', ['organization']),
    isDefaultList() {
      return typeof this.$route.query.organization === "undefined";
    }
  },
  methods: {
    goToWorkflow(destination) {
      this.$router.push({path: `/workflows/${destination.workflowId}`});
    },
    openPriceChangeModal(payload) {
      this.workflowToChange = payload;
    },
    changeWorkflowPrice(payload) {
      this.workflowToChange = undefined;
      this.$store.dispatch('workflow/setWorkflowNewPrice', {...payload, organization: this.$route.params.organization});
    },
    goToOrganization() {
      this.$router.push(`/organizations/${this.organization.uuid}`);
    },
    goToDefaultWorkflows() {
      this.$router.push(`/workflows`);
    }
  },
  created() {
    if (typeof this.$route.query.organization !== "undefined") {
      this.$store.dispatch('workflow/getWorkflows', {organization: this.$route.query.organization});
      this.$store.dispatch('organization/getOrganization', {uuid: this.$route.query.organization})
    } else {
      this.$store.dispatch('workflow/getWorkflows', {});
    }
  }
}
</script>

<style lang="scss"
       scoped>
  .iseq-workflow-list {
    &__content {
      &__title-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        &__button {
          margin-left: 10px;
        }
      }

      &__name-field-hidden {
        color: var(--secondary-color);
      }

      &__price-field {
        display: flex;
        justify-content: flex-end;

        &__icon {
          margin: -4px 4px 0px;
        }
      }

      &__description {
        margin-bottom: 15px;
        color: var(--font-color);

        &__link {
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
</style>
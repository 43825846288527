<template>
  <v-dialog v-model="active"
            persistent
            max-width="600px">
    <div class="price-change-modal">
      <div class="price-change-modal__header">
        <span class="price-change-modal__header__title">
            Price change for workflow:
        </span>

        <span class="price-change-modal__header__workflow-name">
          {{ workflow.name }} currently priced: <strong> {{ workflow.price }} </strong>
        </span>
      </div>

      <v-form v-model="isPriceValid"
              ref="newPriceForm"
              class="price-change-modal__form">

        <v-text-field v-model="newWorkflowPrice"
                      label="New price"
                      outlined
                      :rules="priceRules"></v-text-field>
      </v-form>


      <div class="price-change-modal__buttons">
        <v-iseq-btn @click="closeModal"
                    type="secondary">
          Close
        </v-iseq-btn>

        <v-iseq-btn :disabled="!isPriceValid"
                    type="primary"
                    @click="confirmNewPrice">
          Confirm
        </v-iseq-btn>
      </div>

      <div
          v-show="workflow.hidden"
          class="price-change-modal__warning"
      >
        Please note that this workflow was marked as "hidden" and therefore isn't available for users. Suggested price for such workflows is 0.
      </div>

      <div
          v-show="!fromDefault"
          class="price-change-modal__warning"
      >
        Please note that changing workflow price in one organization will change this price for all organizations that have access to this workflow
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IseqButton from "../ui/IseqButton";

export default {
  name: "WorkflowPriceChangeModal",
  components: {
    "v-iseq-btn": IseqButton
  },
  props: {
    workflow: {
      type: Object,
      required: true
    },
    fromDefault: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => {
    return {
      active: true,
      isPriceValid: false,
      newWorkflowPrice: undefined,
      priceRules: [
        v => !isNaN(Number(v)) || "Given value must be a number",
        v => Number(v) >= 0 || "Price of workflow can't be lower than 0",
        v => Number(v) % 1 === 0 || "Price of workflow must be an integer"
      ]
    }
  },
  methods: {
    confirmNewPrice() {
      this.$emit('confirmed', {workflowId: this.workflow.workflowId, price: this.newWorkflowPrice});
    },
    closeModal() {
      this.$emit('closed');
    }
  },
}
</script>

<style lang="scss"
       scoped>
.price-change-modal {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  color: var(--font-color);

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 1.6em;
    }

    &__workflow-name {
      font-size: 0.9em;
    }
  }

  &__description {
    margin: 0px 10px 10px 10px;

    &__text {

    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .iseq-btn {
      margin-right: 10px;
    }
  }

  &__warning {
    margin-top: 10px;
    font-size: 0.75em;
  }
}
</style>
<template>
  <div class="iseq-navigation-drawer">
    <div class="iseq-navigation-drawer__header">
      <span class="iseq-navigation-drawer__header__text">Navigation</span>
    </div>

    <div class="iseq-navigation-drawer__item-list">
      <v-list>
        <v-list-item v-for="item in items"
                     :key="item.title"
                     :to="item.route">
          <div class="iseq-navigation-drawer__item-list__element">
            <v-icon
                class="element-icon"
                :style="styles(item, false)"
            >
              {{ item.icon }}
            </v-icon>

            <span
                class="element-text"
                :style="styles(item, true)"
            >
                {{ item.title }}
            </span>
          </div>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "IseqNavigationDrawer",
  data: () => ({
    items: [
      {title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/', group: 'dashboard'},
      {title: 'Organizations', icon: 'mdi-account-group', route: '/organizations', group: 'organizations'},
      {title: 'Analyses', icon: 'mdi-poll', route: '/analyses', group: 'analyses'},
      {title: 'Workflows', icon: 'mdi-sitemap', route: '/workflows-selection', group: 'workflows'},
      {title: 'Tools', icon: 'mdi-tools', route: '/tools', group: 'tools'}
    ]
  }),
  methods: {
    styles(item, isTitle) {
      let color;
      if (isTitle) {
        color = this.$vuetify.theme.currentTheme.active;
      } else {
        if (this.$router.currentRoute.meta.group === item.group) {
          color = this.$vuetify.theme.currentTheme.primary;
        } else {
          color = this.$vuetify.theme.currentTheme.inactive;
        }
      }
      return {
        'color': color
      }
    }
  },
  watch: {
    $route() {
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-navigation-drawer {
  &__header {
    margin: 16px;

    &__text {
      color: var(--font-color);
      font-size: 1.7em;
    }
  }

  &__item-list {
    margin-top: -16px;
    padding: 0px 16px;

    &__element {
      display: flex;
      flex-direction: row;
      align-items: center;

      .element-icon {
        margin: 5px 20px 0px 10px;
        padding-bottom: 8px;
      }

      .element-text {
        font-size: 0.8rem;
        letter-spacing: .0333333333em;
        line-height: 1.25rem;
        word-wrap: break-word;
      }
    }
  }
}
</style>
<template>
  <div class="workflow-validator">
    <v-iseq-card title="Workflow validator">
      <span class="workflow-validator__description">
        This tool parses and validates workflow from given URL. If provided workflow does not pass validation a list of
        errors will be shown. Keep in mind that some errors may make validator unable to continue it's work which means
        that not all errors will appear at once. After fixing problems listed by this tool it is recommended to run it
        once again and repeat the whole process until there are no new errors found.
      </span>
    </v-iseq-card>

    <v-iseq-card>
      <div class="workflow-validator__input-row">
        <v-text-field
            v-model="workflowUrl"
            label="Workflow URL"
            outlined
            hide-details
            class="workflow-validator__input-row__url-field"
        ></v-text-field>

        <v-text-field
            v-model="workflowVariant"
            label="Variant"
            outlined
            hide-details
            class="workflow-validator__input-row__variant-field"
        ></v-text-field>

        <v-iseq-button
            @click="runValidation()"
            class="workflow-validator__input-row__button"
        >
          Run validation
        </v-iseq-button>
      </div>
    </v-iseq-card>

    <v-iseq-card
        v-show="errorsGettingWdlFromUrl"
        title="Validator has encountered following errors:"
    >
      <div
          v-if="errorsGettingWdlFromUrl && !errorsGettingWdlFromUrl[0].toString().includes('Error: Request failed with status code 500')"
          class="workflow-validator__errors-list">
        <div
            v-for="error in errorsGettingWdlFromUrl"
            :key="error"
            class="workflow-validator__errors-list__node"
        >
          - <span v-html="error"></span>
        </div>
      </div>

      <div
          v-else
          class="workflow-validator__errors-list"
      >
        Internal error encountered. It means that workflow from given URL couldn't be parsed and no validation was
        possible. There are multiple possible reasons for such situation but the most common ones are: <br />
        - Given value wasn't a valid URL address<br />
        - There isn't a .wdl file under the given URL address<br />
        - Given variant wasn't present within .wdl file from given URL address<br />
        <br />
        If none of the above apply for you, please contact our development team for further problem investigation.
      </div>
    </v-iseq-card>

    <v-iseq-banner
        v-show="validationInProgress"
        value="Validation in progress. Please wait..."
        type="info"
        center-content
    />

    <v-iseq-banner
        v-show="validationSuccess"
        :value="['Workflow has been successfully validated. To see the result click', '']"
        :link="{text: 'here.', address: `/workflows/workflow-validation-result?v=true`}"
        type="success"
        center-content
    />
  </div>
</template>

<script>
import IseqCard from "../../components/ui/IseqCard.vue";
import IseqButton from "../../components/ui/IseqButton.vue";
import IseqBanner from "../../components/ui/IseqBanner.vue";
import {markdownCompile} from "../../helpers/utils";

export default {
  name: "WorkflowValidator",
  components: {
    'v-iseq-card': IseqCard,
    'v-iseq-button': IseqButton,
    'v-iseq-banner': IseqBanner
  },
  data: function () {
    return {
      workflowUrl: "",
      workflowVariant: '1',
      validationInProgress: false,
      errorsGettingWdlFromUrl: undefined,
      validationSuccess: false
    }
  },
  methods: {
    compileMarkdown(data) {
      return markdownCompile(data);
    },
    async runValidation() {
      this.validationInProgress = true;
      this.errorsGettingWdlFromUrl = undefined;
      this.validationSuccess = false;

      // eslint-disable-next-line no-unused-vars
      let validationResult = await this.$store
          .dispatch('workflow/getWorkflowFromURL', {variant: this.workflowVariant, url: this.workflowUrl})
          .catch(rejectionError => {
            this.errorsGettingWdlFromUrl = [rejectionError];
            console.log(rejectionError)
          });

      if (!this.errorsGettingWdlFromUrl) {
        if (validationResult.errors){
          this.errorsGettingWdlFromUrl = validationResult.errors
        } else {
          this.validationSuccess = true;
          this.$store.commit('workflow/setWorkflow', validationResult)
        }
      }
      this.validationInProgress = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.workflow-validator {
  &__description {
    color: var(--font-color);
  }

  &__input-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__url-field {
      width: 100%;
    }

    &__variant-field {
      margin-left: 10px;
      width: 80px;
    }

    &__button {
      margin-left: 15px;
    }
  }

  &__errors-list {
    margin: 0 20px 10px 20px;
    font-size: 14px;
    color: var(--font-color);
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {Auth} from 'aws-amplify';
import Login from '../views/Login'
import OrganizationList from "../views/Organization/OrganizationList";
import OrganizationDetails from "../views/Organization/OrganizationDetails";
import AnalysisDetails from "../views/Analysis/AnalysisDetails";
import AnalysisList from "../views/Analysis/AnalysisList";
import OrganizationCreate from "../views/Organization/OrganizationCreate";
import WorkflowList from "../views/Workflow/WorkflowList";
import OrganizationsWorkflowsList from "../views/Workflow/OrganizationsWorkflowsList";
import WorkflowTypeSelection from "../views/Workflow/WorkflowTypeSelection.view.vue"
import DashboardView from "../views/Dashboard/Dashboard.view.vue";
import WorkflowDetailsView from "../views/Workflow/WorkflowDetails.view.vue";
import ToolsListView from "../views/Tools/ToolsList.view.vue";
import WorkflowValidatorView from "../views/Tools/WorkflowValidator.view.vue";

Vue.use(VueRouter)

const routes = [
  {path: '/login', name: 'Login', component: Login, meta: {requiresAuth: false}},
  {path: '/', name: 'Dashboard', component: DashboardView, meta: {requiresAuth: true, group: 'dashboard'}},
  {path: '/organizations', name: 'OrganizationList', component: OrganizationList, meta: {requiresAuth: true, group: 'organizations'}},
  {path: '/organizations/new', name: 'OrganizationCreate', component: OrganizationCreate, meta: {requiresAuth: true, group: 'organizations'}},
  {path: '/organizations/:uuid', name: 'OrganizationDetails', component: OrganizationDetails, meta: {requiresAuth: true, group: 'organizations'}},
  {path: '/analyses/:uuid', name: 'AnalysisDetails', component: AnalysisDetails, meta: {requiresAuth: true, group: 'analyses'}},
  {path: '/analyses', name: 'AnalysisList', component: AnalysisList, meta: {requiresAuth: true, group: 'analyses'}},
  {path: '/workflows', name: 'WorkflowList', component: WorkflowList, meta: {requiresAuth: true, group: 'workflows'}},
  {path: '/organizations-workflows', name: 'OrganizationsWorkflowsList', component: OrganizationsWorkflowsList, meta: {requiresAuth: true, group: 'workflows'}},
  {path: '/workflows-selection', name: 'WorkflowTypeSelection', component: WorkflowTypeSelection, meta: {requiresAuth: true, group: 'workflows'}},
  {path: '/workflows/:workflowId', name: 'WorkflowDetails', component: WorkflowDetailsView, meta: {requiresAuth: true, group: 'workflows'}},
  {path: '/tools', name: 'ToolsList', component: ToolsListView, meta: {requiresAuth: true, group: 'tools'}},
  {path: '/tools/workflow-validator', name: 'WorkflowValidator', component: WorkflowValidatorView, meta: {requiresAuth: true, group: 'tools'}}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  try {
    await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    store.commit('security/updateToken', session.idToken.jwtToken);
    document.title = "IFLOW Admin Panel";
    next();
  } catch (e) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      store.commit('user/cleanAllData');
      next({path: "/login", query: {redirect: to.fullPath}});
    } else {
      next();
    }
  }
  if (String(to.path) === "/login") {
    try {
      await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      next({path: "/"});
    } catch {
      next();
    }
  }
  next();
});

export default router

<template>
  <div v-show="Object.keys(analysis).length" class="iseq-analysis-details">
    <v-iseq-card :title="`Analysis: ${analysis.name}`" class="iseq-analysis-details__base-info">
      <template v-slot:title-row>
        <div class="status">
          <v-badge :content="analysis.status"
                   :color="checkColorBadge(analysis.status)">
          </v-badge>
        </div>
      </template>

      <div class="iseq-analysis-details__base-info__dates-row"
           v-if="analysis.initiator">

        <div class="">
          <v-icon class="icon">mdi-account</v-icon>
          <span class="thin">Initiator: </span>
          <span class="thick">{{ initiatorName }}</span>
        </div>

        <div class="">
          <v-icon class="icon">mdi-calendar-month</v-icon>
          <span class="thin">Started: </span>
          <span class="thick">{{ analysis.started }}</span>
          <span class="thin"> - Finished: </span>
          <span class="thick">{{ analysis.finished }}</span>
        </div>
      </div>

      <v-iseq-datafield-list :data="datafields"/>
    </v-iseq-card>

    <v-iseq-card
        title="Debug info"
        class="iseq-analysis-details__relationship"
        :noBottomMargin="true"
        tabs-only
        v-if="tab"
    >
      <template v-slot:title-row>
        <div class="iseq-analysis-details__relationship__wrapper">
          <v-tabs height="64px"
                  v-model="tab"
                  color="primary">

            <v-tab class="iseq-analysis-details__relationship__wrapper__tab"
                   v-show="analysis.command && Object.keys(analysis.command).length>0"
                   href="#command">
              <v-icon class="mr-4 title">mdi-cog</v-icon>
              Command
            </v-tab>

            <v-tab class="iseq-analysis-details__relationship__wrapper__tab"
                   v-show="analysis.metadata && Object.keys(analysis.metadata).length>0"
                   href="#metadata">

              <v-icon class="mr-4 title">mdi-database-settings</v-icon>
              Metadata
            </v-tab>

            <v-tab class="iseq-analysis-details__relationship__wrapper__tab"
                   v-show="analysis.jobDetails && Object.keys(analysis.jobDetails).length>0"
                   href="#jobDetails">

              <v-icon class="mr-4 title">mdi-file-cog</v-icon>
              Job details
            </v-tab>

            <v-tab class="iseq-analysis-details__relationship__wrapper__tab"
                   v-show="analysis.cromwellData && Object.keys(analysis.cromwellData).length>0"
                   href="#cromwellData">

              <v-icon class="mr-4 title">mdi-cloud-print</v-icon>
              Cromwell data
            </v-tab>

            <v-tab class="iseq-analysis-details__relationship__wrapper__tab"
                   v-show="analysis.status === 'Succeeded' && analysis.resultsLink"
                   href="#resultsLink">

              <v-icon class="mr-4 title">mdi-link-variant</v-icon>
              Results link
            </v-tab>
          </v-tabs>
        </div>
      </template>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div class="iseq-analysis-details__relationship__gantt-chart"
               v-on="on"
               @click="goToGanttChart">
            <v-icon class="iseq-analysis-details__relationship__gantt-chart__icon">
              mdi-chart-gantt
            </v-icon>

            <span>GANTT'S CHART</span>
          </div>
        </template>
        <span>Gantt's chart will open in new tab</span>
      </v-tooltip>
    </v-iseq-card>

    <v-iseq-card class="iseq-analysis-details__data" v-if="tab">

      <v-tabs-items touchless
                    :value="tab">
        <v-tab-item value="command">
          <json-viewer :value="analysis.command" :expand-depth=1 copyable boxed></json-viewer>
        </v-tab-item>

        <v-tab-item value="metadata">
          <json-viewer :value="analysis.metadata" :expand-depth=1 copyable boxed></json-viewer>
        </v-tab-item>

        <v-tab-item value="jobDetails">
          <json-viewer :value="analysis.jobDetails" :expand-depth=1 copyable boxed></json-viewer>
        </v-tab-item>

        <v-tab-item value="cromwellData">
          <json-viewer :value="analysis.cromwellData" :expand-depth=1 copyable boxed></json-viewer>
        </v-tab-item>

        <v-tab-item value="resultsLink">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span
                @click="saveToClipboard()"
                v-on="on"
                class="iseq-analysis-details__data__link"
              >
                {{analysis.resultsLink}}
                <v-icon color="text">mdi-clipboard-text</v-icon>
              </span>
            </template>
            <span>Click to copy to clipboard</span>
          </v-tooltip>
          <span v-show="copiedTextVisibility" class="iseq-analysis-details__data__copied-text">Copied!</span>
        </v-tab-item>
      </v-tabs-items>
    </v-iseq-card>
  </div>
</template>

<script>
import IseqCard from "@/components/ui/IseqCard"
import {mapState} from "vuex";
import IseqDatafieldList from "@/components/ui/IseqDatafieldList";
import JsonViewer from 'vue-json-viewer'
import Vue from 'vue'

Vue.use(JsonViewer)

export default {
  name: "AnalysisDetails",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-datafield-list": IseqDatafieldList
  },
  data: () => ({
    tab: undefined,
    copiedTextVisibility: false
  }),
  computed: {
    ...mapState('analysis', ['analysis']),
    datafields() {
      return [
        {
          icon: "mdi-microscope",
          name: "Workflow: ",
          value: this.analysis.workflowName,
          route: `/workflows/${this.analysis.workflowId}?version=${this.analysis.workflowVersion}`
        },
        {
          icon: "mdi-microscope",
          name: "Workflow version: ",
          value: this.analysis.workflowVersion
        },
        {
          icon: "mdi-cash",
          name: "Cost: ",
          value: `${this.analysis.chargedCost} credits`,
        },
        {
          icon: "mdi-account-group",
          name: "Organization: ",
          value: this.analysis.organizationName,
          route: `/organizations/${this.analysis.organizationUuid}`
        }
      ]
    },
    initiatorName() {
      if (this.analysis.initiator.name && this.analysis.initiator.name.length > 0) {
        return `${this.analysis.initiator.name} (${this.analysis.initiator.email})`;
      } else {
        return this.analysis.initiator.email;
      }
    }
  },
  methods: {
    checkColorBadge(status) {
      switch (status) {
        case 'Succeeded':
          return 'success';
        case 'Failed':
          return 'red';
        case 'Running':
          return 'blue';
        case 'Aborted':
          return 'grey';
        case 'Deleted':
          return 'grey';
      }
    },
    goToGanttChart() {
      window.open(`${process.env.VUE_APP_API_DX_URL}/analysis/${this.analysis.uuid}/cromwell/timing`, '_blank');
    },
    saveToClipboard() {
      navigator.clipboard.writeText(this.analysis.resultsLink);
      this.copiedTextVisibility = true;
      setTimeout(() => {this.copiedTextVisibility = false;}, 3000)
    }
  },
  watch: {
    analysis(newAnalysis) {
      if (newAnalysis.command && Object.keys(newAnalysis.command).length > 0) {
        this.tab = "command";
      } else if (newAnalysis.metadata && Object.keys(newAnalysis.metadata).length > 0) {
        this.tab = "metadata";
      } else if (newAnalysis.jobDetails && Object.keys(newAnalysis.jobDetails).length > 0) {
        this.tab = "jobDetails";
      } else if (newAnalysis.cromwellData && Object.keys(newAnalysis.cromwellData).length > 0) {
        this.tab = "cromwellData";
      } else {
        this.tab = undefined;
      }
    }
  },
  created() {
    this.$store.dispatch('analysis/getAnalysis', {uuid: this.$route.params.uuid});
  },
  beforeDestroy() {
    this.$store.commit('analysis/clearAnalysis');
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-analysis-details {
  &__base-info {
    .status {
      width: 100%;
      margin-top: 2px;
      margin-left: 15px;
    }

    &__dates-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .icon {
        color: var(--primary-color);
      }

      .thick {
        color: var(--font-color);
        font-weight: bold;
        font-size: 13px;
      }

      .thin {
        font-size: 14px;
        color: var(--font-inverted-color);
      }
    }
  }

  &__relationship {
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &__tab {
        max-width: 200px;
        padding: 0 24px;

        &__icon {
          margin-right: 16px;
        }
      }
    }

    &__gantt-chart {
      margin: 20px 0 0 auto;
      display: flex;
      font-size: 0.875rem;
      font-weight: 500;
      justify-content: center;
      align-items: center;
      min-width: 180px;
      width: 200px;
      height: 64px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &__icon {
        margin-right: 16px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.025);
      }
    }
  }

  &__data {

    &__link {
      color: var(--font-color);
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }

    @keyframes textVisibility{
      0%{
        opacity: 0;
      }
      25%{
        opacity: 1;
      }
      75%{
        opacity: 1;
      }
      100%{
        opacity: 0;
      }
    }

    &__copied-text {
      animation-name: textVisibility;
      animation-duration: 3000ms;
      animation-fill-mode: forwards;

      margin-left: 30px;
      display: inline-block;
      color: rgba(0, 0, 0, 0.54);
      font-size: 1rem;
      font-weight: 500;
      transition: all 500ms;
    }
  }
}
</style>
<template>
  <div :class="['iseq-datafield', data.route ? 'is-link' : 'isnt-link']"
       :style="cssVars"
       @click="routeToDestination">

    <v-icon class="iseq-datafield__icon">
      {{ data.icon }}
    </v-icon>

    <span class="iseq-datafield__name">
      {{ data.name }}
    </span>

    <div
        ref="value-box"
        class="iseq-datafield__value-box"
    >
      <span
          ref="value"
          class="iseq-datafield__value-box__value"
      >
        {{ data.value }}
      </span>

      <div
          v-if="cssVars['--slide-length'] !== '0px'"
          class="fader"
          ref="fader"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "IseqDatafield",
  props: {
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  data: function () {
    return {
      cssVars: {
        '--slide-length': '0px',
        '--slide-time': '1s',
        '--fader-min': '#000000',
        '--fader-max': '#FFFFFF'
      }
    }
  },
  methods: {
    routeToDestination() {
      if (this.data.route) {
        this.$router.push({path: this.data.route});
      }
    }
  },
  updated() {
    let valueBox = this.$refs["value-box"];
    let value = this.$refs["value"];
    if (valueBox.offsetWidth < value.offsetWidth) {
      value.classList.add("iseq-datafield__value-box__value-animate");
      this.cssVars["--slide-length"] = `-${value.offsetWidth - valueBox.offsetWidth + 30}px`;
      this.cssVars["--slide-time"] = `${(value.offsetWidth - valueBox.offsetWidth + 30)/60}s`;
    }
  },
  mounted() {
    this.cssVars["--fader-min"] = this.$vuetify.theme.themes.light.light_2 + '00';
    this.cssVars["--fader-max"] = this.$vuetify.theme.themes.light.light_2 + 'FF';
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-datafield {
  display: flex;
  flex-direction: row;
  width: calc(100% - 6px);
  margin: 5px 3px 5px 3px;
  padding: 12px;
  border-radius: 5px;
  background: var(--light-2);
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--light);
  white-space: nowrap;
  overflow: hidden;

  &__icon {
    font-size: 18px;
    color: var(--active);
    margin-right: 5px;
  }

  &__name {
    color: var(--font-inverted-color);
  }

  &__value-box {
    color: var(--font-color);
    font-weight: bold;
    margin-left: 10px;
    overflow: hidden;

    @keyframes slider {
      0%{
        transform: translateX(0%);
        left: 0;
      }
      100% {
        transform: translateX(-100%);
        left: var(--slide-length);
      }
    }

    &__value {
      overflow: visible;
    }

    &__value-animate{
      &:hover {
        position: relative;
        animation: slider var(--slide-time) forwards;
        animation-timing-function: linear;
        overflow: hidden;
      }
    }

    .fader {
      position: relative;
      top: -100%;
      left: calc(100% - 40px);
      height: 100%;
      width: 40px;
      background: linear-gradient(
              to right,
              var(--fader-min),
              var(--fader-max)
      );
    }
  }
}

.is-link {
  &:hover {
    cursor: pointer;
    background: var(--light);
  }
}

.isnt-link {
  cursor: default;
}
</style>
import store from '@/store'
import router from '@/router'

const messages = {
    ACCESS_FORBIDDEN: "You don't have access to the requested data. If you think that's a mistake please contact your organization's admin.",
};

export function handleForbiddenError() {
    store.dispatch('snackbarQueue/addMessage', {
        message: messages.ACCESS_FORBIDDEN,
        type: "warning"
    });
    router.push("/");
}
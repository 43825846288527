<template>
  <div class="iseq-organization-create">
    <v-iseq-card title="Create new organization">
      <div class="iseq-organization-create__wrapper">
        <div class="input-field">
          <v-form v-model="isNameFormValid"
                  ref="nameForm">
            <v-text-field outlined
                          v-model="organization.name"
                          :rules="nameRules"
                          counter="50"
                          label="Name"></v-text-field>
          </v-form>
        </div>

        <v-text-field class="input-field"
                      outlined
                      v-model="organization.country"
                      label="Country"></v-text-field>

        <v-text-field class="input-field"
                      outlined
                      v-model="organization.city"
                      label="City"></v-text-field>

      </div>

      <div class="iseq-organization-create__wrapper">
        <v-select class="input-field"
                  v-model="organization.type"
                  label="type"
                  outlined
                  :items="types"
        ></v-select>

        <v-text-field class="input-field"
                      outlined
                      v-model="organization.website"
                      label="Website"></v-text-field>

        <div class="input-field">
          <v-form v-model="isTopUpFormValid"
                  ref="nameForm">
            <v-text-field outlined
                          v-model="organization.initialTopUp"
                          :rules="topUpRules"
                          label="Initial Top Up"></v-text-field>
          </v-form>
        </div>
      </div>

      <div class="iseq-organization-create__content__wrapper">
        <v-textarea outlined
                    :rows='5'
                    label="Description"
                    auto-grow
                    v-model="organization.description"
        ></v-textarea>
      </div>
    </v-iseq-card>

    <v-iseq-card
        no-title
        no-bottom-margin
        class="iseq-organization-create__create-section"
    >
      <template #title-row>
        <div class="iseq-organization-create__create-section__button">
          <v-tooltip
              :disabled="isNameFormValid && isTopUpFormValid"
              top
          >
            <template #activator="{on}">
              <div v-on="on">
                <v-iseq-btn
                    large
                    width="wide"
                    @click="createOrganization"
                    :disabled="!isNameFormValid || ! isTopUpFormValid"
                >
                  Create organization
                </v-iseq-btn>
              </div>
            </template>
            <span> {{ createOrganizationDisabledReasons }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-iseq-card>
  </div>
</template>

<script>
import IseqCard from "../../components/ui/IseqCard";
import IseqButton from "../../components/ui/IseqButton";
import {mapState} from "vuex";

export default {
  name: "OrganizationCreate",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton
  },
  computed: {
    ...mapState('organization', ['organizations']),
    createOrganizationDisabledReasons() {
      if (!this.isNameFormValid) {
        return "Organization name is invalid";
      } if (!this.isTopUpFormValid) {
        return "Top up value is invalid";
      } else {
        return "";
      }
    }
  },
  data: function () {
    return {
      organization: {
        name: "",
        country: "",
        city: "",
        type: "Business",
        website: "",
        position: "",
        description: "",
        initialTopUp: 100
      },
      types: [
        "Business",
        "Research Institute",
        "University",
        "Laboratory",
        "Non - profit organization",
        "Individual"
      ],
      isNameFormValid: false,
      isTopUpFormValid: true,
      nameRules: [
        v => (!!v && v.length > 0) || "Name cannot be empty",
        v => v.length <= 50 || "Name cannot be longer than 50 characters",
        v => !this.organizations.some(element => element.name === v) || "Organization named " + v + " already exists in our system"
      ],
      topUpRules: [
        v => (new RegExp('^[0-9]*$', 'g')).test(v) || "Top up value must be an integer equal or higher than 0",
      ]
    }
  },
  methods: {
    createOrganization() {
      this.$store.dispatch('organization/createOrganization', this.organization);
    },
  },
  created() {
    this.$store.dispatch('organization/getOrganizations');
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-organization-create {
  &__wrapper {
    margin: 5px 20px;
    display: flex;
    flex-direction: row;

    .input-field {
      width: 33%;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__create-section {
    &__button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}
</style>
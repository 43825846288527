<template>
  <div class="iseq-app-bar">
    <div class="iseq-app-bar__logo">
      <v-img alt="IntelliseqFlow Logo"
             contain
             @click="goToDashboard"
             :src="logoPath"
             transition="scale-transition"
             height="32px"></v-img>
    </div>

    <div class="iseq-app-bar__user">
      <v-menu bottom left :offset-y='true' :close-on-click='true'>
        <template v-slot:activator="{ on }">
          <div class="logout" v-on="on">
            <v-avatar size="36" class="logout__avatar">
              <img v-if="user.avatar"
                   :src="user.avatar"
                   :alt="user.name"/>

              <img v-else
                   src="@/assets/avatar.svg"
                   :alt="user.name"/>
            </v-avatar>

            <div class="logout__username">
              <span>{{ !user.name || user.name.length === 0 ? user.email : user.name }}</span>
            </div>

            <v-icon color="active">mdi-menu-down</v-icon>
          </div>
        </template>

        <v-list>
          <v-list-item @click="goToIflow">
            <v-list-item-title>
              <v-icon color="active" class="logout__icon">mdi-logout-variant</v-icon>
              Go to IFLOW
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-title>
              <v-icon color="active" class="logout__icon">mdi-logout-variant</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import {Auth} from 'aws-amplify'
import {mapState} from "vuex";

export default {
  name: "IseqAppBar",
  data() {
    return {
      logoPath: require(process.env.VUE_APP_LOGO_LIGHT_PATH)
    }
  },
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    logout() {
      this.$store.dispatch('cleanAllData');
      this.signOut();
    },
    goToIflow() {
      if (process.env.VUE_APP_BASE_ADDRESS) {
        window.location.replace(`https://${process.env.VUE_APP_MODE}.${process.env.VUE_APP_BASE_ADDRESS}`);
      } else if (process.env.VUE_APP_MODE !== 'prod') {
        window.location.replace(`https://${process.env.VUE_APP_MODE}.flow.intelliseq.com`);
      } else {
        window.location.replace(`https://flow.intelliseq.com`);
      }
    },
    goToDashboard() {
      this.$router.push("/");
    },
    async signOut() {
      try {
        await Auth.signOut();
        await this.$router.push('/login');
      } catch (e) {
        throw new Error(e);
      }
    }
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-app-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  &__logo {
    width: 300px;
    margin-left: -16px;
  }

  &__user {
    margin-right: 16px;
    margin-left: auto;

    .logout {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &__avatar {
        width: 36px;
        margin-right: 8px;
      }

      &__username {
        color: var(--light);
        font-weight: 700;
        font-size: 1.1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 8px;
        padding-top: 8px;
        max-width: 75%;
      }

      &__icon {
        margin-right: 4px;
      }
    }
  }
}
</style>

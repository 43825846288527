import DOMPurify from 'dompurify'
import marked from 'marked';

export function pagingDataParse(data) {
    // In case of no data specified the default paging settings will be returned
    if(!data) {
        return generatePagingData({});
    }

    const direction = data.sortDesc[0] ? 'desc' : 'asc';
    const sortBy = data.sortBy[0] ? data.sortBy[0] : '';
    const itemsPerPage = data.itemsPerPage !== -1 ? data.itemsPerPage : data.totalElements;

    return `page=${data.page - 1}&size=${itemsPerPage}&sort=${sortBy},${direction}`
}

export function generatePagingData(payload) {
    if (payload.raw) {
        return {
            page: 1,
            itemsPerPage: payload.pageSize ? payload.pageSize : 10,
            sortBy: [],
            sortDesc: []
        }
    } else {
        return `page=0&size=${payload.pageSize ? payload.pageSize : 10}`;
    }
}

export function timeZoned(date) {
    if ((new Date(date)).toString() !== "Invalid Date" && date !== null) {
        const timeZonedDate = new Date(new Date(date).getTime() - (new Date().getTimezoneOffset()) * 60 * 1000);
        const timeZonedString = timeZonedDate.toLocaleString('en-US', {hourCycle: "h23"});
        const gmt = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
        return `${timeZonedString} ${gmt}`
    } else {
        return date
    }
}

export function capitalizeFirst(text) {
    return text ? text.charAt(0) + text.slice(1).toLowerCase() : '';
}

export function markdownCompile(data) {
    return data ? DOMPurify.sanitize(marked.parse(data.toString().replace(/\\n/g, "\n"))) : data;
}

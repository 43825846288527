import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// eslint-disable-next-line no-unused-vars
import Amplify, * as AmplifyModules from "aws-amplify"
import { AmplifyPlugin } from "aws-amplify-vue"
import awsmobile from '@/plugins/aws-exports'
import './plugins/axios.js'

Vue.config.productionTip = false

Amplify.configure(awsmobile)

Vue.use(AmplifyPlugin, AmplifyModules)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import request    from '@/helpers/request.js'
import store from '@/store'
import router from "../../router";

export default {
    namespaced: true,
    state: {
        organization: {},
        organizations: [],
        organizationAnalyses: [],
        organizationMembers: [],
    },
    mutations: {
        setOrganizations(state, payload) {
            state.organizations = payload;
        },
        setOrganization(state, payload) {
            state.organization = payload;
        },
        setOrganizationAnalyses(state, payload) {
            state.organizationAnalyses = payload;
        },
        setOrganizationMembers(state, payload) {
            state.organizationMembers = payload;
        },
        setOrganizationS3CredentialsStatus(state, payload) {
            state.organization = {...state.organization, s3CredentialsStatus: !!payload.credentials};
        },
        cleanAllData(state) {
            state.organizations = [];
            state.organization = {};
            state.organizationAnalyses = [];
            state.organizationMembers = [];
        }
    },
    actions: {
        getOrganizations({commit}) {
            request.request({
                endpoint: `admin/organizations`,
                callback: (data) => {
                    // Adding field with the name of the owner
                    let organizations = data.map(organization => {
                        if (!organization.owners || organization.owners.length === 0) {
                            organization.owner = "NO OWNER";
                        } else if (organization.owners[0].name && organization.owners[0].name.length > 0) {
                            organization.owner = `${organization.owners[0].name} (${organization.owners[0].email})`
                        } else {
                            organization.owner = organization.owners[0].email
                        }
                        return organization;
                    });
                    commit('setOrganizations', organizations);
                }
            })
        },
        getOrganization({commit, dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}`,
                callback: (data) => {
                    data.owner = `${data.owners[0].name && data.owners[0].name.length>0 ? data.owners[0].name + ' (' + data.owners[0].email + ')' : data.owners[0].email}`;
                    commit('setOrganization', data);
                    dispatch('getOrganizationAnalyses', payload);
                    dispatch('getOrganizationMembers', payload);
                    dispatch('getOrganizationS3CredentialsStatus', payload.uuid);
                }
            })
        },
        getOrganizationAnalyses({commit}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/analyses`,
                callback: (data) => {
                    commit('setOrganizationAnalyses', data);
                }
            })
        },
        getOrganizationMembers({commit}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/members`,
                callback: (data) => {
                    commit('setOrganizationMembers', data);
                }
            })
        },
        // eslint-disable-next-line no-empty-pattern
        changeOrganizationOwner({dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/change-owner`,
                method: 'PUT',
                data: payload.data,
                callback: () => {
                    dispatch('getOrganizationMembers',{uuid: payload.uuid});
                }
            })
        },
        topUpOrganization({dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/top-up`,
                method: "post",
                data: {
                    value: payload.amount
                },
                callback: () => {
                    dispatch('getOrganization', {uuid: payload.uuid});
                }
            })
        },
        deleteOrganization({dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}`,
                method: "delete",
                callback: () => {
                    dispatch('getOrganization', payload);
                    store.dispatch('snackbarQueue/addConfirmationMessage', {type: "organizationDeleted"});
                }
            })
        },
        upgradeOrganization({dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/upgrade`,
                method: "post",
                callback: () => {
                    dispatch('getOrganization', {uuid: payload.uuid});
                    store.dispatch('snackbarQueue/addConfirmationMessage', {type: "organizationUpgraded"});
                }
            })
        },
        createOrganization({dispatch}, data) {
            request.request({
                endpoint: "admin/organizations",
                method: "post",
                data: data,
                callback: function (data) {
                    dispatch('getOrganizations');
                    router.push({path: `/organizations/${data.uuid}`});
                }
            })
        },
        setS3Credentials({dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/aws`,
                method: "post",
                data: payload.data,
                callback: () => {
                    dispatch('getOrganizationS3CredentialsStatus', payload.uuid);
                }
            })
        },
        getOrganizationS3CredentialsStatus({commit}, payload) {
            request.request({
                endpoint: `organizations/${payload}/aws`,
                callback: (data) => {
                    commit('setOrganizationS3CredentialsStatus', data);
                }
            })
        },
        deleteS3Credentials({dispatch}, payload) {
            request.request({
                endpoint: `admin/organizations/${payload.uuid}/aws`,
                method: "delete",
                data: payload.data,
                callback: () => {
                    dispatch('getOrganizationS3CredentialsStatus', payload.uuid);
                }
            })
        },
    }
}

import store from '@/store';

export default {
    namespaced: true,
    state: {
        id: 0,
        items: [],
        missingConnectionSnackbarId: undefined
    },
    mutations: {
        addItem: (state, payload) => {
            state.id++;

            state.items.push({
                id: state.id,
                message: payload.message,
                icon: payload.icon,
                type: payload.type,
            });
            if (payload.callback){
                payload.callback(state.id);
            }
            if (payload.timeout) {
                store.commit("snackbarQueue/removeItem", {id: state.id, timeout: payload.timeout});
            }
        },
        removeItem: (state, payload) => {
            const itemToDelete = state.items.find(item => item.id === payload.id);
            let timeout = payload.force ? 0 : (payload.timeout || 3500);

            if (itemToDelete.type !== 'error' || payload.force) {
                setTimeout(() => {
                    state.items.splice(state.items.findIndex(item => item.id === payload.id), 1);
                }, timeout)
            }
        },
        setMissingConnectionSnackbarId: (state, payload) => {
            state.missingConnectionSnackbarId = payload;
        },
        cleanAllData(state) {
            state.id = 0;
            state.items = [];
        },
    },
    actions: {
        addMessage({commit}, payload) {
            if (payload.message) {
                return new Promise((resolve) => {
                    commit('addItem', {
                        message: payload.message,
                        callback: resolve,
                        type: payload.type
                    });
                });
            } else {
                return undefined;
            }
        },
        addConfirmationMessage({commit}, payload) {
            const type = {
                organizationUpgraded: {icon: "mdi-arrow-up-circle-outline", message: "Organization has been upgraded"},
                organizationDeleted: {icon: "mdi-account", message: "Organization has been deleted"}
            };

            commit('addItem', {
                message: type[payload.type].message,
                icon: type[payload.type].icon,
                type: "success",
                timeout: payload.timeout || 5000
            });
        },
        addMissingConnectionSnackbar({state, commit, dispatch}) {
            if (state.missingConnectionSnackbarId) {
                commit("removeItem", {force: true, id: state.missingConnectionSnackbarId});
            }
            dispatch('addMessage', {
                message: "No Internet connection. Trying to reconnect…",
                type: "warning"
            }).then( response => {
                commit("setMissingConnectionSnackbarId", response);
            });
        },
        removeMissingConnectionSnackbar({state, commit}) {
            if (state.missingConnectionSnackbarId) {
                commit("removeItem", {force: true, id: state.missingConnectionSnackbarId});
            }
            commit("setMissingConnectionSnackbarId", undefined);
        }
    },
};

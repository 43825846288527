import Vue from 'vue'
import request from '@/helpers/request.js'
import store from '@/store'
import router from '@/router'
import VueCookies from 'vue-cookies'

export default {
    namespaced: true,
    state: {
        client_id: "web_app",
        accessToken: '',
    },
    mutations: {
        updateToken: (state, token) => {
            if (token !== state.accessToken) {
                state.accessToken = token;
                store.dispatch('user/getUser');
            }
        },
		cleanAllData(state){
            state.client_id = "web_app";
            state.accessToken = '';
        }
    },
    actions: {

        // eslint-disable-next-line no-empty-pattern
        login({}, response) {
            VueCookies.set("refresh_token", response.refresh_token, "7D");
            store.commit('security/updateToken', response.access_token);
            store.dispatch('user/getUser')
        },

        // eslint-disable-next-line no-empty-pattern
        loginWithCredentials({}, credentials) {
            request.request({
                service: "API_SECURITY",
                endpoint: "oauth/token",
                method: "post",
                reqData: {
                    "grant_type": "password",
                    "username": credentials.login,
                    "password": credentials.password
                },
                headers: {
                    'authorization': 'Basic d2ViX2FwcDpzZWNyZXQ=',
                    'content-Type': 'application/x-www-form-urlencoded'
                },
                callback: function (data) {
                    store.dispatch('security/login', data);
                    router.push("/");
                } // calback:
            }) // request()
        },

        loginWithRefreshToken() {
            request.request({
                service: "API_SECURITY",
                endpoint: "oauth/token",
                method: "post",
                reqData: {
                    "grant_type": "refresh_token",
                    "client_id": "web_app",
                    "refresh_token": Vue.cookies.get("refresh_token")
                },
                headers: {
                    'Authorization': 'Basic d2ViX2FwcDpzZWNyZXQ=',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                callback: function (data) {
                    store.dispatch("security/login", data)

                }, // calback:
                errorCallback: function () {
                }
            }) // request()
        } // loginWithRefreshToken()

    } // actions:
} // securityModule:

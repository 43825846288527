import request    from '@/helpers/request.js'
import {Auth} from "aws-amplify";

export default {
    namespaced: true,
    state: {
        user: {}
    },
    getters: {
        user(state) {
            return state.user
        },
        userCredits(state) {
          return state.userCredits;
        }
    },
    mutations: {
        setUserData(state, data) {
            state.user = data;
        },
        cleanAllData(state) {
            state.user = {};
        }
    },
    actions: {
        getUser({commit, dispatch}) {
            dispatch('checkUserAdminStatus');
            request.request({
                endpoint: "user",
                method: "get",
                callback: function (data) {
                    commit('setUserData', data);
                }
            })
        },
        checkUserAdminStatus() {
            request.request({
                endpoint: "admin",
                callback: (data) => {
                    if (!data.admin) {
                      Auth.signOut();
                      if (process.env.VUE_APP_BASE_ADDRESS) {
                        window.location.replace(`https://${process.env.VUE_APP_MODE}.${process.env.VUE_APP_BASE_ADDRESS}`);
                      } else {
                        window.location.replace(`https://${process.env.VUE_APP_MODE}.flow.intelliseq.com`);
                      }
                    }
                }
            })
        }
    }
}

<template>
    <div class="iseq-analysis-list">
        <v-iseq-card class="iseq-analysis-list__content" title="Analyses">
            <v-data-table :headers="tableColumns"
                          @click:row="goToAnalysisDetails"
                          :server-items-length="totalElements"
                          :options.sync="analysisTableOptions"
                          :footer-props="{
                                          'items-per-page-options': itemsPerPageOptions
                                         }"
                          :items="analyses">
            </v-data-table>
        </v-iseq-card>
    </div>
</template>

<script>
import {mapState} from "vuex";
import IseqCard from "@/components/ui/IseqCard"

export default {
    name: "AnalysisList",
    components: {
        "v-iseq-card": IseqCard
    },
    data: function() {
        return {
            itemsPerPageOptions: [10, 20, 30, -1],
            tableColumns: [
                {text: 'Name', value: 'name', width: "300px"},
                {text: 'Workflow', value: 'workflowName', width: "200px"},
                {text: 'Organization', value: 'organizationName', width: "200px"},
                {text: 'Started', value: 'started', width: "200px"},
                {text: 'Finished', value: 'finished', width: "200px"},
                {text: 'Status', value: 'status', width: "120px"}
            ]
        }
    },
    computed: {
        ...mapState('analysis', ['analyses', 'totalElements']),
        analysisTableOptions: {
            get() {
                return this.$store.getters['analysis/analysisTableOptions'];
            },
            set(newValue) {
                this.$store.commit('analysis/setAnalysisTableOptions', newValue);
                this.$store.dispatch('analysis/getAnalyses');
            }
        }
    },
    methods: {
        goToAnalysisDetails(event) {
            this.$router.push({path: `/analyses/${event.uuid}`});
        }
    },
    created() {
        this.$store.dispatch('analysis/getAnalyses');
    }
}
</script>

<style lang="scss"
       scoped>
    .iseq-analysis-list{
        &__content {
        }
    }
</style>
<template>
  <div class="workflow-type-selection">
    <v-card
        class="workflow-type-selection__tab"
        @click="navigateTo('/workflows')"
    >
      <v-icon
          class="workflow-type-selection__tab__logo"
          color="primary"
          size="200px"
      >
        mdi-sitemap
      </v-icon>

      <span class="workflow-type-selection__tab__text">Default workflows</span>
    </v-card>

    <v-card
        class="workflow-type-selection__tab"
        @click="navigateTo('/organizations-workflows')"
    >
      <v-icon
          class="workflow-type-selection__tab__logo"
          color="primary"
          size="200px"
      >
        mdi-human-male-height
      </v-icon>

      <span class="workflow-type-selection__tab__text">Organization-specific workflows</span>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "WorkflowTypeSelection",
  methods: {
    navigateTo(address) {
      this.$router.push(address);
    }
  }
}
</script>

<style lang="scss" scoped>
.workflow-type-selection {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  &__tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    cursor: pointer;

    &__logo {
      margin: 40px 0 20px 0;
    }

    &__text {
      display: block;
      margin-bottom: 20px;
      color: var(--font-color);
      font-size: 1.7em;
      font-weight: bold;
    }
  }
}
</style>
<template>
  <div class="tools-list">
    <v-card
        class="tools-list__tab"
        @click="navigateTo('/tools/workflow-validator')"
    >
      <v-icon
          class="tools-list__tab__logo"
          color="primary"
          size="200px"
      >
        mdi-calculator-variant-outline
      </v-icon>

      <span class="tools-list__tab__text">Workflow validator</span>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ToolsList",
  methods: {
    navigateTo(address) {
      this.$router.push(address);
    }
  }
}
</script>

<style lang="scss" scoped>
.tools-list {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  &__tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    cursor: pointer;

    &__logo {
      margin: 40px 0 20px 0;
    }

    &__text {
      display: block;
      margin-bottom: 20px;
      color: var(--font-color);
      font-size: 1.7em;
      font-weight: bold;
    }
  }
}
</style>
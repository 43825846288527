import request    from '@/helpers/request.js'
import {generatePagingData, pagingDataParse, timeZoned} from "../../helpers/utils";

export default {
    namespaced: true,
    state: {
        analysis: {},
        analyses: [],
        analysisTableOptions: generatePagingData({raw: true}),
        totalElements: 0,
    },
    mutations: {
        setAnalysis(state, payload) {
            state.analysis = payload;
        },
        setAnalyses(state, payload) {
            payload.map(analysis => {
                analysis.started = timeZoned(analysis.started);
                analysis.finished = timeZoned(analysis.finished);
                return analysis
            });
            for (let i=0;i<payload.length;i++){
                if (payload[i].status==="Aborted") payload[i].finished="Aborted";
                else if (!payload[i].finished) payload[i].finished="In progress"
            }
            state.analyses = payload;
        },
        setAnalysisTableOptions(state, payload) {
            state.analysisTableOptions = payload;
        },
        clearAnalysisTableOptions(state) {
            state.analysisTableOptions =  generatePagingData({raw: true});
        },
        setTotalElements(state, payload) {
            state.totalElements = payload;
        },
        clearAnalysis(state) {
            state.analysis = {};
        },
        cleanAllData(state) {
            state.user = {};
        }
    },
    getters: {
        analysisTableOptions: (state) => {
            return state.analysisTableOptions;
        }
    },
    actions: {
        getAnalysis({commit}, payload) {
            request.request({
                endpoint: `admin/analyses/${payload.uuid}`,
                callback: (data) => {
                    commit('setAnalysis', data);
                }
            })
        },
        getAnalyses({state, commit}) {
            const pagingParams = pagingDataParse({
                sortDesc: state.analysisTableOptions.sortDesc,
                sortBy: state.analysisTableOptions.sortBy,
                itemsPerPage: state.analysisTableOptions.itemsPerPage,
                totalElements: state.totalElements,
                page: state.analysisTableOptions.page
            });

            request.request({
                endpoint: `admin/analyses?${pagingParams}`,
                callback: (data) => {
                    commit('setAnalyses', data.content);
                    commit("setTotalElements", data.totalElements);
                }
            })
        }
    }
}

<template>
  <v-dialog v-model="active"
            persistent
            max-width="600px">
    <div class="ooc-modal">
      <div class="ooc-modal__header">
        <span class="ooc-modal__header__title">
            Change organization owner:
        </span>
      </div>

      <div class="ooc-modal__header__description">
        Current owner of organization is: {{`${currentOwner.name ? currentOwner.name + ' - ' : ''}${currentOwner.email}`}}
      </div>

      <div class="ooc-modal__form">
        <span>New owner:</span>
        <v-select
            v-model="newOwner"
            :item-text="memberDescription"
            item-value="uuid"
            :items="organizationMembersWithoutCurrentOwner"
            outlined
        />
        <span>New role for previous owner:</span>
        <v-select
            v-model="previousOwnerNewRole"
            item-text="name"
            item-value="value"
            :items="organizationPositions"
            outlined
        />
      </div>


      <div class="ooc-modal__buttons">
        <v-iseq-btn @click="closeModal"
                    type="secondary">
          Cancel
        </v-iseq-btn>

        <v-iseq-btn type="primary"
                    :disabled="newOwner === undefined"
                    @click="changeOrganizationOwner">
          Confirm
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IseqButton from "../ui/IseqButton";

export default {
  name: "OrganizationOwnerChangeModal",
  components: {
    "v-iseq-btn": IseqButton
  },
  props: {
    organizationUuid: {
      type: String
    },
    organizationMembers: {
      type: Array,
      default: () => {
      }
    }
  },
  computed: {
    organizationMembersWithoutCurrentOwner() {
      return this.organizationMembers.filter(e => e.role !== "Organization owner");
    },
    currentOwner(){
      return this.organizationMembers.find(m => m.role === "Organization owner");
    }
  },
  data: () => {
    return {
      active: true,
      newOwner: undefined,
      previousOwnerNewRole: "ORGANIZATION_MEMBER",
      organizationPositions: [
        {name: "Admin", value: "ORGANIZATION_ADMIN"},
        {name: "Member", value: "ORGANIZATION_MEMBER"}
      ]
    }
  },
  methods: {
    changeOrganizationOwner() {
      this.$store.dispatch('organization/changeOrganizationOwner', {
        data: {
          newOrganizationOwner: this.newOwner,
          previousOwnerNewRole: this.previousOwnerNewRole,
        },
        uuid: this.organizationUuid
      });
      this.$emit('closed');
    },
    closeModal() {
      this.$emit('closed');
    },
    memberDescription(member) {
      return `${member.name ? member.name + ' - ' : ''}${member.email} (${member.role})`;
    }
  },
}
</script>

<style lang="scss"
       scoped>
.ooc-modal {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  color: var(--font-color);

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 1.6em;
    }

    &__description{
      margin-bottom: 10px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .iseq-btn {
      margin-right: 10px;
    }
  }

  &__warning {
    margin-top: 10px;
    font-size: 0.75em;
  }
}
</style>
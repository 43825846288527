<template>
  <div class="iseq-organization-list">
    <v-iseq-card class="iseq-organization-list__content" title="Organizations">
      <template v-slot:title-row>
        <div class="iseq-organization-list__content__title-row">
          <v-iseq-btn @click="goToDefaultWorkflows"
                      class="iseq-organization-list__content__title-row__button"
                      width="200px">
            Go to list of default workflows
          </v-iseq-btn>
        </div>
      </template>

      <v-data-table :headers="tableColumns"
                    @click:row="goToOrganizationsWorkflows"
                    :items="filteredOrganizations"/>
    </v-iseq-card>
  </div>
</template>

<script>
import IseqButton from "../../components/ui/IseqButton";
import IseqCard from "../../components/ui/IseqCard";
import {mapState} from "vuex";

export default {
  name: "OrganizationsWorkflowsList",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton
  },
  data: function () {
    return {
      tableColumns: [
        {text: 'Organization', value: 'name'},
      ]
    }
  },
  computed: {
    ...mapState('organization', ['organizations']),
    filteredOrganizations() {
      return this.organizations.filter(organization => organization.status !== "DELETED" && organization.hasIndividualWdls);
    }
  },
  methods: {
    goToOrganizationsWorkflows(event) {
      this.$router.push({path: `/workflows?organization=${event.uuid}`});
    },
    goToDefaultWorkflows() {
      this.$router.push({path: '/workflows'});
    }
  },
  created() {
    this.$store.dispatch('organization/getOrganizations');
  }
}
</script>

<style lang="scss" scoped>
.iseq-organization-list {
  &__content {
    &__title-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;

      &__button {
        margin-left: 10px;
      }
    }

    .deleted-organization-name {
      text-decoration: line-through;
    }
  }
}
</style>
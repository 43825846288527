<template>
  <v-iseq-card class="dashboard" title="Dashboard">
    <!--  Check for not parsed WDLs  -->
    <v-iseq-banner
        :value="notParsedWorkflows.length !== 0 ? 'Following workflows did not pass validation:' : 'All workflows passed validation successfully'"
        :type="notParsedWorkflows.length !== 0 ? 'warning' : 'success'"
        center-content
    >
      <template v-if="notParsedWorkflows.length !== 0">
        <div
            v-for="node in notParsedWorkflows"
            :key="node.url.toString()"
            class="dashboard__not-parsed-workflows"
        >
          <span
              class="dashboard__not-parsed-workflows__url"
              @click="changeValueOf(node.url)"
          >
            <v-icon color="white">
              {{ !visibleErrors[node.url] ? 'mdi-menu-down' : 'mdi-menu-up' }}
            </v-icon>

            {{ node.url }}
          </span>

          <div v-if="!!visibleErrors[node.url]">
            <div
                v-for="errorText in node.errors"
                :key="errorText"
                class="dashboard__not-parsed-workflows__error-node"
            >
              -<span v-html="errorText" />
            </div>
          </div>
        </div>
      </template>
    </v-iseq-banner>

    <!--  Check for WDLs without prices  -->
    <v-iseq-banner
        :value="wdlsWithoutPrice.length ? `Following workflow${wdlsWithoutPrice.length === 1 ? ' doesn\'t have its price' : 's don\'t have their prices'}  set.` : 'All workflows have their prices set.'"
        :type="wdlsWithoutPrice.length ? 'warning' : 'success'"
        center-content
    >
      <template v-if="wdlsWithoutPrice.length">
        <div
            v-for="node in wdlsWithoutPrice"
            :key="node.workflowId"
            class="dashboard__workflows-without-prices"
        >
          <span
              class="dashboard__workflows-without-prices__link-node"
              @click="goToWorkflow(node.workflowId)"
          >
            - {{ node.name }}
          </span>
        </div>
      </template>
    </v-iseq-banner>
  </v-iseq-card>
</template>

<script>
import {mapState} from "vuex";
import IseqCard from "../../components/ui/IseqCard.vue";
import IseqBanner from "../../components/ui/IseqBanner.vue";

export default {
  name: "Dashboard",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-banner": IseqBanner
  },
  data: function () {
    return {
      visibleErrors: {}
    }
  },
  computed: {
    ...mapState('workflow', ['notParsedWorkflows', 'wdlsWithoutPrice'])
  },
  methods: {
    changeValueOf(nodeAddress) {
      this.$nextTick(() => {
        this.visibleErrors[nodeAddress] = !this.visibleErrors[nodeAddress];
      });
      this.$forceUpdate();
    },
    goToWorkflow(id) {
      this.$router.push(`/workflows/${id}`);
    }
  },
  created: function () {
    this.$store.dispatch('workflow/getWdlsWithoutPrice');
    this.$store.dispatch('workflow/getNotParsedProdWorkflows');
  },
}
</script>

<style lang="scss" scoped>
.dashboard{
  padding: 20px;

  &__not-parsed-workflows {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__url {
      cursor: pointer;
    }

    &__error-node {
      margin-left: 30px;
    }

    &__error-node:last-of-type {
      margin-bottom: 10px;
    }
  }

  &__workflows-without-prices {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__link-node {
      cursor: pointer;
    }
  }
}
</style>
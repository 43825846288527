<template>
  <v-dialog
    v-model="active"
    persistent
    :max-width="width"
  >
    <div class="simple-confirmation-modal">
      <div
        v-if="header"
        class="simple-confirmation-modal__header"
      >
        <span class="simple-confirmation-modal__header__title">{{ header }}</span>
      </div>

      <div
        v-if="message"
        class="simple-confirmation-modal__message"
      >
        {{ message }}
      </div>

      <div
        v-else
        class="simple-confirmation-modal__message"
      >
        <slot name="message" />
      </div>

      <div class="simple-confirmation-modal__buttons">
        <v-iseq-btn
          :type="rejectButtonType"
          @click="rejectAction()"
        >
          {{ rejectButtonText }}
        </v-iseq-btn>

        <v-iseq-btn
          :type="confirmButtonType"
          @click="confirmAction()"
        >
          {{ confirmButtonText }}
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IseqButton from "@/components/ui/IseqButton"

export default {
  name: "SimpleConfirmationModal",
  components: {
    "v-iseq-btn": IseqButton
  },
  props: {
    header: {
      type: String,
    },
    message: {
      type: String,
    },
    rejectButtonText: {
      type: String,
      default: "reject"
    },
    rejectButtonType: {
      type: String,
      default: "primary"
    },
    confirmButtonText: {
      type: String,
      default: "confirm"
    },
    confirmButtonType: {
      type: String,
      default: "primary"
    },
    width: {
      type: String,
      default: "600px"
    }
  },
  data: function() {
    return {
      active: true
    }
  },
  methods: {
    rejectAction() {
      this.$emit('actionRejected');
    },
    confirmAction() {
      this.$emit('actionConfirmed');
    }
  }
}
</script>

<style lang="scss"
       scoped>
.simple-confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 20px;

    &__title {
      color: var(--dark);
      font-size: 1.2em;
      font-weight: 500;
    }
  }

  &__message {
    margin-bottom: 20px;
    color: var(--dark);
    font-size: 1em;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .iseq-btn {
      margin-left: 4px;
    }
  }
}
</style>
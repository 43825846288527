<template>
    <v-app :style="cssProps">
        <v-navigation-drawer app
                             clipped
                             width="300px"
                             v-if="isUserLoggedIn"
                             class="iseq-app__navigation-drawer">
            <v-iseq-navigation-drawer/>
        </v-navigation-drawer>

        <v-app-bar app
                   clipped-left
                   v-if="isUserLoggedIn"
                   color="navbar_bg"
                   height="80px"
                   class="iseq-app__top-bar">
            <v-iseq-app-bar/>
        </v-app-bar>

        <v-main>
            <v-container fluid class="iseq-app__main">
                <router-view/>
            </v-container>
        </v-main>

        <v-iseq-snackbar/>

        <v-footer v-if="false" app class="iseq-app__footer">
            <!-- -->
        </v-footer>
    </v-app>
</template>

<script>

import {mapState} from "vuex";
import IseqAppBar from "./components/layout/IseqAppBar";
import IseqNavigationDrawer from "./components/layout/IseqNavigationDrawer";
import IseqSnackbarQueue from "./components/IseqSnackbarQueue";

export default {
    name: 'App',

    components: {
        "v-iseq-app-bar": IseqAppBar,
        "v-iseq-navigation-drawer": IseqNavigationDrawer,
        "v-iseq-snackbar": IseqSnackbarQueue
    },

    data: () => ({
        onLine: navigator.onLine
    }),

    computed: {
        ...mapState('user', ['user']),
        isUserLoggedIn() {
            return Object.keys(this.user).length > 0;
        },
        cssProps() {
            return {
                '--active': this.$vuetify.theme.currentTheme.active,
                '--inactive': this.$vuetify.theme.currentTheme.inactive,
                '--dark': this.$vuetify.theme.currentTheme.dark,
                '--light': this.$vuetify.theme.currentTheme.light,
                '--light-2': this.$vuetify.theme.currentTheme.light_2,
                '--font-color': this.$vuetify.theme.currentTheme.text,
                '--font-inverted-color': this.$vuetify.theme.currentTheme.textinverted,
                '--primary-color': this.$vuetify.theme.currentTheme.primary,
                '--secondary-color': this.$vuetify.theme.currentTheme.secondary,
                '--tertiary-color': this.$vuetify.theme.currentTheme.tertiary,
                '--warning-light-color': this.$vuetify.theme.currentTheme.warning_light,
                '--warning-color': this.$vuetify.theme.currentTheme.warning,
                '--error-color': this.$vuetify.theme.currentTheme.error,
                '--danger-color': this.$vuetify.theme.currentTheme.danger,
                '--success-color': this.$vuetify.theme.currentTheme.success,
                '--button': this.$vuetify.theme.currentTheme.button,
                '--border-inactive': this.$vuetify.theme.currentTheme.borderinactive,
                '--navbar-background-color': this.$vuetify.theme.currentTheme.navbar_bg,
                '--border-text': this.$vuetify.theme.currentTheme.bordertext,
                '--main-top-margin': this.mainTopMargin,
                'max-width': "1400px",
                'margin': "0 auto"
            }
        },
    },

    methods: {
        updateOnlineStatus(e) {
            const {type} = e;
            this.onLine = type === 'online';
        },
    },

    watch: {
        onLine(newValue) {
            this.$store.dispatch('setAppOnlineStatus', newValue);
        }
    },

    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    }
};
</script>

<style lang="scss">
    body {
        background: #f7fafc;
    }

    .v-main {
        background: #f7fafc;
    }

    .iseq-app{
        &__top-bar{

        }
        &__navigation-drawer{

        }
        &__main{
            background: #f7fafc;
            width: 100%;
        }
        &__footer{
            width: 100%;
            height: 150px;
        }
    }

    .v-data-table td, .v-data-table th {
        border-bottom: 1px solid white !important;
        border-top: 1px solid white !important;
    }

    th.text-start {
        font-size: 1.4em !important;
    }

    .text-start, .v-data-footer__select, .v-data-footer__pagination, .v-data-footer__icons-before, .v-data-footer__icons-after, .v-select__selection, .v-input__icon i, .v-list-item__title {
        color: var(--dark);
    }

    tbody tr {
        cursor: pointer;
    }

    tbody tr:nth-of-type(odd) {
        background-color: var(--light);
        border-radius: 25px;
    }

    .v-data-table td:first-child {
        border-top-left-radius: 5px;
    }

    .v-data-table td:last-child {
        border-top-right-radius: 5px;
    }

    .v-data-table td:first-child {
        border-bottom-left-radius: 5px;
    }

    .v-data-table td:last-child {
        border-bottom-right-radius: 5px;
    }
</style>
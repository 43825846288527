import Vue from 'vue'
import Vuex from 'vuex'
import security from './modules/security.js';
import user from './modules/user.js';
import snackbarQueue from "./modules/snackbarQueue";
import organization from "./modules/organization";
import analysis from "./modules/analysis"
import workflow from "./modules/workflow"
import store from '@/store';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        appOnlineStatus: true
    },
    mutations: {
        setAppOnlineStatus: (state, payload)=> {
            state.appOnlineStatus = payload;
        }
    },
    actions: {
        setAppOnlineStatus({commit}, payload) {
            if (payload) {
                store.dispatch("snackbarQueue/removeMissingConnectionSnackbar");
                store.dispatch("snackbarQueue/addConfirmationMessage", {type: "internetAccessRetrieved"});
            } else {
                store.dispatch("snackbarQueue/addMissingConnectionSnackbar");
            }
            commit("setAppOnlineStatus", payload);
        },
        cleanAllData() {
            store.commit('security/cleanAllData');
            store.commit('user/cleanAllData');
            store.commit('snackbarQueue/cleanAllData');
            store.commit('organization/cleanAllData');
            store.commit('analysis/cleanAllData');
            store.commit('workflow/cleanAllData');
        }
    },
    getters: {
        isAppOnline: (state) => {
            return state.appOnlineStatus;
        }
    },
    modules: {
        user,
        security,
        snackbarQueue,
        organization,
        analysis,
        workflow
    }
})

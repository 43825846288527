<template>
  <div class="iseq-organization-list">
    <v-iseq-card class="iseq-organization-list__content" title="Organizations">
      <template v-slot:title-row>
        <div class="iseq-organization-list__content__title-row">
          <v-iseq-btn @click="goToCreateNewOrganization"
                      class="iseq-organization-list__content__title-row__button"
                      width="200px">
            Create new organization
          </v-iseq-btn>
          <v-iseq-btn @click="changeDeletedOrganizationsVisibility()"
                      class="iseq-organization-list__content__title-row__button"
                      width="200px">
            {{ deletedOrganizationsVisibility ? `Hide deleted organizations` : `Show deleted organizations` }}
          </v-iseq-btn>
        </div>
      </template>

      <div class="iseq-organization-list__content__search-bar-row">
        <v-text-field
            v-model="search"
            label="Search"
            outlined
            hide-details
            class="iseq-organization-list__content__search-bar-row__search-bar"
        />
      </div>

      <v-data-table :headers="tableColumns"
                    @click:row="goToOrganizationDetails"
                    :items="filteredOrganizations"
                    :search="search"
      >

        <template #item.name="{ item }">
          <span :class="[item.status === 'DELETED' ? `deleted-organization-name` : ``]">{{ item.name }}</span>
          <span v-if="item.status === 'DELETED'"> (DELETED)</span>
        </template>

        <template #item.creationDate="{ item }">
          {{ item.creationDate.split(' ')[0] }}
        </template>

        <template #item.owners="{ item }">
          {{ item.owner }}
        </template>

        <template #item.accountStatus="{ item }">
          {{ capitalizeFirst(item.accountStatus) }}
        </template>

        <template #item.hasIndividualWdls="{ item }">
          {{ item.hasIndividualWdls ? 'True' : '' }}
        </template>
      </v-data-table>
    </v-iseq-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import IseqCard from "@/components/ui/IseqCard";
import IseqButton from "../../components/ui/IseqButton";
import {capitalizeFirst} from "../../helpers/utils";

export default {
  name: "OrganizationList",
  components: {
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton
  },
  data: function () {
    return {
      deletedOrganizationsVisibility: false,
      tableColumns: [
        {text: 'Organization', value: 'name'},
        {text: 'Owner', value: 'owners'},
        {text: 'Created', value: 'creationDate', width: "130px"},
        {text: 'Credits', value: 'credits', width: "130px"},
        {text: 'Status', value: 'accountStatus', width: "120px"},
        {text: 'Has own WDLs', value: 'hasIndividualWdls', width: "220px"}
      ],
      search: "",
    }
  },
  computed: {
    ...mapState('organization', ['organizations']),
    filteredOrganizations() {
      if (this.deletedOrganizationsVisibility) {
        return this.organizations;
      } else {
        return this.organizations.filter(organization => organization.status !== "DELETED");
      }
    }
  },
  methods: {
    goToOrganizationDetails(event) {
      this.$router.push({path: `/organizations/${event.uuid}`});
    },
    changeDeletedOrganizationsVisibility() {
      this.deletedOrganizationsVisibility = !this.deletedOrganizationsVisibility;
    },
    username(item) {
      return item.name && item.name.length > 0 ? `${item.name} (${item.email})` : item.email;
    },
    goToCreateNewOrganization() {
      this.$router.push({path: `/organizations/new`});
    },
    capitalizeFirst(text) {
      return capitalizeFirst(text);
    }
  },
  created() {
    this.$store.dispatch('organization/getOrganizations');
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-organization-list {
  &__content {
    &__title-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;

      &__button {
        margin-left: 10px;
      }
    }

    &__search-bar-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;

      &__search-bar {
        max-width: 410px;
      }
    }

    .deleted-organization-name {
      text-decoration: line-through;
    }
  }
}
</style>
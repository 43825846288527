<template>
    <v-text-field v-bind="$attrs"
                  v-model="inputVal"
                  class="iseq-text-field v-text-field"></v-text-field>
</template>

<script>
export default {
    name: "IseqTextField",
    props: ['value'],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>

<style lang="scss"
       scoped>
    .iseq-text-field {
        background-color: var(--light);
        border: white solid 0px;
        border-radius: 10px;
        padding: 10px 5px 10px 10px;
    }

    .iseq-text-field:hover {
        background-color: var(--tertiary-color);
    }

    // TODO - following styles don't work



</style>

<style lang="scss">
    .v-text-field > .v-input__control > .v-input__slot::before {
        display: none !important;
    }

    .iseq-text-field input {
        color: var(--font-color) !important;
    }
</style>
<template>
  <div class="iseq-organization-details">
    <v-iseq-card :title="`Organization: ${organization.name}`">
      <template v-slot:title-row>
        <v-badge
          v-if="organization.status === 'DELETED'"
          content="DELETED"
          color="red"
          class="status"
        ></v-badge>
      </template>

      <v-iseq-datafield-list :data="datafields"/>
    </v-iseq-card>

    <v-iseq-card
      v-if="organization.status !== 'DELETED'"
      title="Actions"
      class="iseq-organization-details__actions"
    >
      <v-iseq-btn
        width="wide"
        class="iseq-organization-details__actions__button"
        @click="topUpModalVisibility = true"
      >
        Top up organization
      </v-iseq-btn>

      <v-iseq-btn
        v-if="organization.accountStatus !== 'STANDARD'"
        width="wide"
        class="iseq-organization-details__actions__button"
        @click="upgradeStatusModalVisibility = true"
      >
        Upgrade organization
      </v-iseq-btn>

      <v-iseq-btn
          v-if="organization.s3CredentialsStatus"
          width="wide"
          type="red"
          class="iseq-organization-details__actions__button"
          @click="deleteS3CredentialsModalVisibility = true"
      >
        Delete S3 credentials
      </v-iseq-btn>

      <v-iseq-btn
        v-else
        width="wide"
        class="iseq-organization-details__actions__button"
        @click="setS3CredentialsModalVisibility = true"
      >
        Set S3 credentials
      </v-iseq-btn>

      <v-iseq-btn
          width="wide"
          class="iseq-organization-details__actions__button"
          @click="organizationOwnerChangeModalVisibility = true"
      >
        Change organization owner
      </v-iseq-btn>

      <v-iseq-btn
        class="iseq-organization-details__actions__button"
        type="red"
        width="wide"
        @click="organizationDeleteModalVisibility = true"
      >
        Delete organization
      </v-iseq-btn>
    </v-iseq-card>

    <v-iseq-card title="Analyses">
      <v-data-table
        :headers="analysesColumns"
        @click:row="goToAnalysisDetails"
        :items="organizationAnalyses"
      >
        <template #item.status="{ item }">
          <div class="iseq-organization-details__analyses__table__field">
            <v-badge
              :content="item.status"
              class="field-badge"
              :color="checkColorBadge(item.status)"
            ></v-badge>
          </div>
        </template>
      </v-data-table>
    </v-iseq-card>

    <v-iseq-card title="Members">
      <v-data-table
          :headers="membersColumns"
          :items="organizationMembers"
      />
    </v-iseq-card>

    <simple-confirmation-modal
      v-if="upgradeStatusModalVisibility"
      width="500px"
      header="Upgrade organization status"
      message="Do you want to update organization's status from Trial to Standard? This action cannot be reversed."
      reject-button-text="Cancel"
      reject-button-type="secondary"
      confirm-button-text="Upgrade"
      confirm-button-type="primary"
      @actionRejected="upgradeStatusModalVisibility = false"
      @actionConfirmed="upgradeOrganizationStatus()"
    />

    <top-up-modal
      v-if="topUpModalVisibility"
      @closed="topUpModalVisibility = false"
      :organization-uuid="organization.uuid"
    />

    <set-s3-credentials-modal
      v-if="setS3CredentialsModalVisibility"
      @closed="setS3CredentialsModalVisibility = false"
      :organization-uuid="organization.uuid"
    />

    <organization-delete-modal
      v-if="organizationDeleteModalVisibility"
      @closed="organizationDeleteModalVisibility = false"
      @confirmed="deleteOrganization()"
    />

    <simple-confirmation-modal
      v-if="deleteS3CredentialsModalVisibility"
      @actionRejected="deleteS3CredentialsModalVisibility = false"
      @actionConfirmed="deleteS3Credentials()"
      confirm-button-text="Delete"
      reject-button-text="Cancel"
      confirm-button-type="red"
      header="Delete S3 credentials for this organization"
    />

    <organization-owner-change-modal
      v-if="organizationOwnerChangeModalVisibility"
      @closed="organizationOwnerChangeModalVisibility = false"
      :organization-members="organizationMembers"
      :organization-uuid="organization.uuid"
    />
  </div>
</template>

<script>
import IseqCard from "@/components/ui/IseqCard"
import {mapState} from "vuex";
import IseqDatafieldList from "@/components/ui/IseqDatafieldList";
import IseqButton from "@/components/ui/IseqButton";
import TopUpModal from "@/components/modals/TopUpModal";
import OrganizationDeleteModal from "../../components/modals/OrganizationDeleteModal";
import {capitalizeFirst} from "../../helpers/utils";
import SimpleConfirmationModal from "../../components/modals/SimpleConfirmationModal";
import SetS3CredentialsModal from "@/components/modals/setS3CredentialsModal.vue";
import OrganizationOwnerChangeModal from "@/components/modals/OrganizationOwnerChangeModal.vue";

export default {
  name: "OrganizationDetails",
  components: {
    OrganizationOwnerChangeModal,
    SetS3CredentialsModal,
    SimpleConfirmationModal,
    'top-up-modal': TopUpModal,
    "v-iseq-card": IseqCard,
    "v-iseq-datafield-list": IseqDatafieldList,
    "v-iseq-btn": IseqButton,
    "organization-delete-modal": OrganizationDeleteModal
  },
  data: () => ({
    topUpModalVisibility: false,
    organizationDeleteModalVisibility: false,
    upgradeStatusModalVisibility: false,
    setS3CredentialsModalVisibility: false,
    deleteS3CredentialsModalVisibility: false,
    organizationOwnerChangeModalVisibility: false,
    analysesColumns: [
      {text: 'Name', value: 'name', width: "350px"},
      {text: 'Started', value: 'started'},
      {text: 'Finished', value: 'finished'},
      {text: 'Status', value: 'status'},
      {text: 'Cost', value: 'chargedCost', sortable: false},
    ],
    membersColumns: [
      {text: 'Name', value: 'name'},
      {text: 'Email', value: 'email'},
      {text: 'Role', value: 'role'},
    ],
  }),
  beforeDestroy() {
    this.$store.commit('organization/setOrganization', {});
    this.$store.commit('organization/setOrganizationAnalyses', []);
    this.$store.commit('organization/setOrganizationMembers', []);
  },
  computed: {
    ...mapState('organization', ['organization', 'organizationAnalyses', 'organizationMembers']),
    datafields() {
      let fields = [
        {
          icon: "mdi-tooltip-text",
          name: "Website: ",
          value: this.organization.website
        },
        {
          icon: "mdi-domain",
          name: "Type: ",
          value: this.organization.type
        },
        {
          icon: "mdi-longitude",
          name: "Country: ",
          value: this.organization.country
        },
        {
          icon: "mdi-map-marker",
          name: "Location: ",
          value: this.organization.city
        },
        {
          icon: "mdi-account-multiple",
          name: "Number of members: ",
          value: this.organization.numberOfMembers
        },
        {
          icon: "mdi-account",
          name: "Owner",
          value: this.organization.owner
        },
        {
          icon: "mdi-album",
          name: "Status",
          value: capitalizeFirst(this.organization.accountStatus)
        },
        {
          icon: "mdi-circle-multiple",
          name: "Credits",
          value: this.organization.credits
        },
        {
          icon: "mdi-sitemap",
          name: "Has own WDLs: ",
          value: this.organization.hasIndividualWdls ? "True" : '',
          route: `/workflows?organization=${this.organization.uuid}`
        }
      ];
      return fields.filter(field => !!field.value);
    }
  },
  methods: {
    checkColorBadge(status) {
      switch (status) {
        case 'Succeeded':
          return 'success';
        case 'Failed':
          return 'red';
        case 'Running':
          return 'blue';
        case 'Aborted':
          return 'grey';
        case 'Deleted':
          return 'grey';
      }
    },
    goToAnalysisDetails(event) {
      this.$router.push({path: `/analyses/${event.uuid}`});
    },
    deleteOrganization() {
      this.organizationDeleteModalVisibility = false;
      this.$store.dispatch('organization/deleteOrganization', {uuid: this.organization.uuid});
    },
    upgradeOrganizationStatus() {
      this.upgradeStatusModalVisibility = false;
      this.$store.dispatch('organization/upgradeOrganization', {uuid: this.organization.uuid});
    },
    deleteS3Credentials() {
      this.deleteS3CredentialsModalVisibility = false;
      this.$store.dispatch('organization/deleteS3Credentials', {uuid: this.organization.uuid});
    }
  },
  created() {
    this.$store.dispatch('organization/getOrganization', {uuid: this.$route.params.uuid});
  }
}
</script>

<style lang="scss"
       scoped>
.iseq-organization-details {

  .status {
    margin: 20px auto auto 15px;
    font-weight: bold;
  }

  &__analyses {
    &__table {
      padding: 16px;

      &__field {
        height: 100%;
        display: flex;
        align-items: center;

        .field-badge {
          margin-top: 12px;
        }
      }
    }
  }

  &__actions {
    &__button {
      margin-right: 10px;
    }
  }
}
</style>
<template>
  <div class="iseq-workflow-details">
    <v-iseq-banner
        v-if="$route.query.version && workflow.version && $route.query.version !== workflow.version"
        :value="`Link you have used to get here uses an old version of this workflow (${$route.query.version }). Workflow visible below is the current newest version (${workflow.version}).`"
        type="warning"
        center-content
    />

    <v-iseq-banner
        v-if="workflow.hidden"
        value="Displayed workflow has a 'hidden' attribute set which means it won't be displayed for users in main application."
        type="info"
        center-content
    />

    <v-iseq-banner
        v-if="workflow.name && (workflow.price === undefined || workflow.price === null)"
        value="Displayed workflow doesn't have its price set."
        type="warning"
        center-content
    />

    <v-iseq-card
        class="iseq-workflow-details__header"
        :title="`Workflow: ${workflow.name}`"
        no-bottom-margin>
      <template v-slot:title-row>
          <v-iseq-btn
              class="iseq-workflow-details__header__button"
              @click="changePrice = true"
          >
            Change price
          </v-iseq-btn>
      </template>
    </v-iseq-card>

    <v-iseq-card
      class="iseq-workflow-details__card"
      title="Description"
    >
      <div
          class="iseq-workflow-details__card__field"
          v-html="compileMarkdown(workflow.description)"
      />

      <div
          v-if="isLongDescriptionOpen && workflow.longDescription"
          class="iseq-workflow-details__card__field"
          v-html="compileMarkdown(workflow.longDescription)"
      />

      <div
          v-if="workflow.longDescription"
          class="iseq-workflow-details__card__field"
          @click="isLongDescriptionOpen = !isLongDescriptionOpen"
      >
        <span class="iseq-workflow-details__card__field__text">
          {{ `${isLongDescriptionOpen ? 'Show less' : 'Read more...'}` }}
        </span>
      </div>
    </v-iseq-card>

    <v-iseq-card
      class="iseq-workflow-details__card"
      title="Details"
    >
      <v-iseq-datafield-list
          class="iseq-workflow-details__card__field"
          :data="datafields"
      />
    </v-iseq-card>

    <workflow-price-change-modal v-if="changePrice"
                                 :workflow="workflow"
                                 :from-default="false"
                                 @closed="changePrice = false"
                                 @confirmed="changeWorkflowPrice"/>
  </div>
</template>

<script>
import IseqCard from "../../components/ui/IseqCard.vue";
import IseqButton from "../../components/ui/IseqButton.vue";
import {mapState} from "vuex";
import {markdownCompile} from "../../helpers/utils";
import IseqDatafieldList from "../../components/ui/IseqDatafieldList.vue";
import WorkflowPriceChangeModal from "../../components/modals/WorkflowPriceChangeModal.vue";
import IseqBanner from "../../components/ui/IseqBanner.vue";

export default {
  name: "WorkflowDetails",
  components: {
    WorkflowPriceChangeModal,
    "v-iseq-card": IseqCard,
    "v-iseq-btn": IseqButton,
    "v-iseq-datafield-list": IseqDatafieldList,
    "v-iseq-banner": IseqBanner
  },
  data: function() {
    return {
      isLongDescriptionOpen: false,
      changePrice: false
    }
  },
  computed: {
    ...mapState('workflow', ['workflow']),
    datafields() {
      return [
        {
          icon: "mdi-tournament",
          name: "Name: ",
          value: this.workflow.name
        },
        {
          icon: "mdi-selection-multiple",
          name: "Type: ",
          value: this.workflow.type
        },
        {
          icon: "mdi-account",
          name: "Author: ",
          value: this.workflow.author
        },
        {
          icon: "mdi-copyright",
          name: "Copyright: ",
          value: this.workflow.copyright
        },
        {
          icon: "mdi-cash",
          name: "Price: ",
          value: this.workflow.price
        }
      ]
    }
  },
  created() {
    if (!this.$route.query.v) {
      this.$store.dispatch('workflow/getWorkflow', {workflowId: this.$route.params.workflowId});
    }
  },
  methods: {
    compileMarkdown(data) {
      return markdownCompile(data);
    },
    changeWorkflowPrice(payload) {
      this.changePrice = false;
      this.$store.dispatch('workflow/setWorkflowNewPrice', {...payload, workflowId: this.workflow.workflowId});
    },
  },
  beforeDestroy() {
    this.$store.commit('workflow/cleanWorkflow');
  }
}
</script>

<style lang="scss" scoped>
.iseq-workflow-details {
  &__header {
    &__button {
      margin-left: 10px;
    }
  }

  &__card {
    &__field {
      margin: 0px 20px 10px 20px;
      font-size: 14px;
      color: var(--font-inverted-color);

      &__text {
        color: var(--font-color);
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
</style>